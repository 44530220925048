import { debounce } from 'lodash'
import * as React from 'react'

import { Strings } from 'utils'

import SensitiveInput from 'shared/SensitiveInput'

import { getUserProfile } from './Queries'
import { FormType, UserType, PaymentMethodEnum } from './Types'
import { updateProfile } from 'app/UserProfile/UserProfileMutations'

import {
  Container,
  Item,
  Label,
  Row,
  StyledDropdown,
  StyledInput,
} from './Styled'

const PaymentInfoStrings = Strings.userProfile.paymentInfo

interface Props {
  userID: string
}

interface State {
  form: FormType
  user: UserType
}

const PAYMENT_METHOD_OPTIONS = [
  { key: PaymentMethodEnum.Direct, value: PaymentMethodEnum.Direct, text: PaymentMethodEnum.Direct },
  { key: PaymentMethodEnum.Ach, value: PaymentMethodEnum.Ach, text: PaymentMethodEnum.Ach },
  { key: PaymentMethodEnum.Check, value: PaymentMethodEnum.Check, text: PaymentMethodEnum.Check },
  { key: PaymentMethodEnum.PayPal, value: PaymentMethodEnum.PayPal, text: PaymentMethodEnum.PayPal }
]

export default class PaymentInfo extends React.Component<Props, State> {
  public debounceJob: any = null

  public state = {
    user: {} as UserType,
    form: {} as FormType,
  }

  public async componentDidMount() {
    const { userID } = this.props

    const user: UserType = await getUserProfile(userID) || {} as UserType
    const form: FormType = {
      accountNumber: user.accountNumber || '',
      bankName: user.bankName || '',
      routingNumber: user.routingNumber || '',
      ein: user.ein || '',
      paymentMethod: user.paymentMethod || '',
      taxID: user.taxID || '',
      taxReportName: user.taxReportName || '',
    }

    this.setState({
      form,
      user,
    })
  }

  public render() {
    const {
      form,
      user,
    } = this.state

    return (
      <Container>
        <Row>
          <Label>{PaymentInfoStrings.taxReportingName} :</Label>
          <Item>
            <StyledInput
              placeholder={PaymentInfoStrings.taxReportingName}
              name='taxReportName'
              value={form.taxReportName}
              onChange={this.handleChange}
            />
          </Item>
        </Row>
        <Row>
          <Label>{PaymentInfoStrings.einNumber} :</Label>
          <Item>
            <SensitiveInput
              mask='99-9999999'
              maxLength={9}
              name='ein'
              onChange={this.handleChange}
              placeholder={PaymentInfoStrings.einNumber}
              value={form.ein}
            />
          </Item>
        </Row>
        <Row>
          <Label>{PaymentInfoStrings.taxId} :</Label>
          <Item>
            <SensitiveInput
              mask='999-99-9999'
              maxLength={9}
              name='taxID'
              onChange={this.handleChange}
              placeholder={PaymentInfoStrings.taxId}
              value={form.taxID}
            />
          </Item>
        </Row>
        <Row>
          <Label>{PaymentInfoStrings.paymentMethod} :</Label>
          <Item>
            <StyledDropdown
              disabled={user.role === 'GUEST'}
              name='paymentMethod'
              placeholder='Payment Method'
              selection={true}
              value={form.paymentMethod}
              options={PAYMENT_METHOD_OPTIONS}
              onChange={this.handleChange}
            />
          </Item>
        </Row>
        {(form.paymentMethod === PaymentMethodEnum.Ach || form.paymentMethod === PaymentMethodEnum.Direct) && (
          <React.Fragment>
            <Row>
              <Label>{PaymentInfoStrings.bankName} :</Label>
              <Item>
                <StyledInput
                  placeholder={PaymentInfoStrings.bankName}
                  name='bankName'
                  value={form.bankName}
                  onChange={this.handleChange}
                />
              </Item>
            </Row>
            <Row>
              <Label>{PaymentInfoStrings.routingNumber} :</Label>
              <Item>
                <SensitiveInput
                  placeholder={PaymentInfoStrings.routingNumber}
                  name='routingNumber'
                  value={form.routingNumber}
                  onChange={this.handleChange}
                />
              </Item>
            </Row>
            <Row>
              <Label>{PaymentInfoStrings.accountNumber} :</Label>
              <Item>
                <SensitiveInput
                  placeholder={PaymentInfoStrings.accountNumber}
                  name='accountNumber'
                  value={form.accountNumber}
                  onChange={this.handleChange}
                />
              </Item>
            </Row>
          </React.Fragment>
        )}
      </Container>
    )
  }

  private handleUpdate = async () => {
    const { user: { _id }, form } = this.state

    if (this.debounceJob) {
      this.debounceJob.cancel()
    }

    this.debounceJob = debounce(async () => {
      await updateProfile(form, { _id })
    }, 1000)

    this.debounceJob()
  }

  private handleChange = async (e: React.SyntheticEvent<HTMLDivElement> | any, { name, value }: any) => {
    const { form } = this.state
    form[name] = value
    this.setState({ form }, () => {
      this.handleUpdate()
    })

  }
}
