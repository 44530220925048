import { Action } from 'redux'

export enum PaymentTypeEnum {
  Credit = 'Credit',
  Debit = 'Debit',
  None = '',
  Referral = 'Referral',
}

export enum PaymentNumericEnum {
  Fixed = 'FIXED',
  Percent = 'PERCENTAGE'
}

export enum PaymentKeyEnum {
  CommissionFee = 'commissionFee',
  LocalTax = 'localTax',
  OtherFee = 'otherFee',
  ReferralFee = 'referralFee',
  StateTax = 'stateTax',
  TransactionFee = 'transactionFee',
}

export enum TransactionRoleEnum {
  Buyer = 'Buyer',
  Seller = 'Seller',
  Both = 'Both',
}

export enum TransactionStatusEnum {
  New = 'New',
  Updated = 'Updated',
  Active = 'Active',
  Received = '$$ Received',
  Payable = 'Payable',
  Closed = 'Closed',
  Failed = 'Failed',
}

export interface EmailType {
  type: string
  value: string
}

export interface PhoneType {
  type: string
  value: string
}

export interface ReferralType {
  _id: string
  type: string
  feeObj: FeeType
  amount: number
}

export interface Activities {
  _id: string
  type: string
  task: {
    name: string
  }
  owner: Owner
  description: string
  updatedAt: string
}

export interface ShareWith {
  _id?: string
  emails?: EmailType
  firstName?: string
  lastName?: string
  phones?: PhoneType
  profileImage?: string
  role?: string
  userName?: string
}

export interface Images {
  _id: string
  name: string
  url: string
}

export interface Property {
  _id: string
  type: {
    _id: string
    name: string
  }
  subType: {
    _id: string
    name: string
  }
  address: {
    _id: string
    type: string
    streetNumber: string
    streetName: string
    city: string
    state: string
    country: string
    zipCode: string
  }
  images: Images[]
  mlsId: string
  price: number
}

export interface Owner {
  _id: string
  firstName: string
  lastName: string
}

export interface TransactionType {
  _id: string
  status: string
  owner: Owner
}

export interface Commission {
  _id?: string | any
  referrals?: string | any
  fees?: string | any
  expectedCommission?: string | number | any
  closingDate?: Date
  receivedDate?: Date
  transaction?: TransactionType | any
  actualCommission?: string | number | any
  commissionDue: number
  totalCredits: number | any
  totalDebits: number
  totalExpenses: number
  totalReferrals: number | any
}

export interface TotalCommissions {
  commissionDue: number
  totalCredits: number
  totalDebits: number
  totalReferrals: number
}

export interface Address {
  streetNumber: string
  streetName: string
  city: string
  state: string
  zipCode: string
}

/** TODO
  * This is actually just a Transaction.
  * Should be deprecated and replaced by
  * TransactionType from src/app/Transactions/Details/Types.ts
  */
export interface TransactionList {
  _id: string
  address: Address
  avatar: string
  date: string
  images: string[]
  mslID: number
  name: string
  price: number
  transactionRole: string
  shareWith: ShareWith[]
  propertyId: Property
  owner: Owner
  progress:
    | {
        current: number
        total: number
      }
    | number
  createdAt: string
  updatedAt: string
  status: string
  checked?: boolean
}

export interface TransactionFee {
  _id: string
  name: string
  value: string
  currency: string
}

export interface TransactionReferralFee {
  _id: string
  type: string
  currency: string
  amount: string
  brokerageName?: string
  brokerageTaxID?: string
  phone?: string
  streetNo?: number
  streeName?: string
  state?: string
  city?: string
  zipCode?: number
  refereeName?: string
  office?: string
  referee?: string
}

/** TODO:
  * this is actually the definition for a TransactionBoard
  */
export interface Transaction {
  _id: string
  description: string
  icon: string
  items: TransactionList[]
  name: string
}

export interface Messages {
  _id: string
  message: string
  createdBy: {
    _id: string
    firstName: string
    profileImage: string
  }
  createdAt: string
}

export interface ManagerNotes {
  _id: string
  createdAt: string
  createdBy: {
    _id: string
    firstName: string
    profileImage: string
  }
  description: string
}

export interface TransactionWorkflow {
  _id: string
  assignedTo: string
  dueIn: string
  status: string
  task: string
  taskDescription: string
  checked?: boolean
  dueDate: string
  taskTime: string
  type: string
  name: string
  description: string
  sharedWith: TransactionShareWith[]
}

export interface PropertyImageType {
  _id: string
  url: string
  name: string
  order: number
}

export interface TransactionNotes {
  date: string
  id: string
  notes: string
  profile: string
}

export interface FeeType {
  _id?: string
  type?: PaymentNumericEnum
  fee?: number
}

export interface CreditDebitType {
  _id: string
  amount: number
  feeObj: FeeType
  isDefault: boolean
  key: PaymentKeyEnum
  name: string
  type: PaymentTypeEnum
}

export interface UserType {
  _id: string
  role: string
  transactionOwnerId?: string | any
}

export interface IconsType {
  _id: string
  key: string
  imageUrl: string
}

export interface FiltersData {
  address?: {
    city?: string
    state?: string
    streetName?: string
    streetNumber?: string
    zipCode?: string
  }
  mlsId?: number
  owner?: {
    firstName?: string
    lastName?: string
  }
  transactionId?: number
  transactionRole?: string | TransactionRoleEnum
  totalDebits?: number,
  expectedCommission?: number,
  status?: string | TransactionStatusEnum,
}

export interface Filter {
  filter: boolean
  searchText: boolean
}

export interface Options {
  key: string
  text: string
  value: string
}

export interface FilterText {
  id: string
  content: string
  text: string
}

export interface TotalCommissions {
  totalDebits: number
  totalCredits: number
  totalReferrals: number
  totalIncome: number
  totalExpenses: number
  commissionDue: number
}

export interface Filter {
  filter: boolean
  searchText: boolean
}

export interface TransactionPassFilter {
  status: string
  timePeriod: number
  userId: string
}

/** TODO:
  * Rewrite to use an _id-mapped transaction object of complete transactions
  * store all related data within a transaction, top-down
  * store current view as list of _ids referencing the mapped transaction object
  * store pagination and filters for current view
  * see: https://trello.com/c/JRTdmT43/440-improve-caching
  */
export interface TransactionState {
  allTransactions: Transaction[]
  listData: any
  currentlyDeleteCard: TransactionList | null
  boardId: string
  cardIndex: string
  commission: any
  messages: Messages[]
  fee: TransactionFee[]
  referralFee: any
  managerNotes: ManagerNotes[]
  shareWith: any
  transactionDetail: any
  workflowTask: any
  documents: any
  documentChecklist: any
  propertyImages: PropertyImageType[]
  credits: any
  totalCommission: any
  documentCount: number
  messageCount: number
  isApproved: boolean
  isReleased: boolean
  toggleNotesModal: boolean
  creditDebit: any
  toggleTab: boolean
  filter: Filter
  searchLoader: boolean
  showDefaultView: boolean
  searchData: any
  showDocumentLayout: boolean
  officeFilter: string
  transactionOwnerID: string
  userPassTransactionFilter: TransactionPassFilter
  confetti: boolean
}

// Actions
export interface GetAllRecentTransactionsAction extends Action {
  type: string
  payload: {
    allTransactions: Transaction[]
    allListTransactions: any
  }
}

export interface TransactionAddNewBoard extends Action {
  type: string
  payload: {
    index: number
    newData: Transaction
  }
}

export interface TransactionAddNewCard extends Action {
  type: string
  payload: {
    id: string
    index: string
    newData: TransactionList
  }
}

export interface TransactionSetNewIcon extends Action {
  type: string
  payload: {
    id: string
    icon: string
  }
}

export interface TransactionSetNewDescription extends Action {
  type: string
  payload: {
    id: string
    description: string
  }
}

export interface TransactionSetNewBoardName extends Action {
  type: string
  payload: {
    id: string
    boardName: string
  }
}

export interface TransactionDeleteCard extends Action {
  type: string
  payload: {
    boardId: string
    cardId: string
  }
}

export interface TransactionDeleteRecord extends Action {
  type: string
  payload: {
    cardId: string
  }
}

export interface TransactionRestoreCard extends Action {
  type: string
}

export interface TransactionResetCardData extends Action {
  type: string
}

export interface TransactionAddNewMessage extends Action {
  type: string
  payload: {
    newData: Messages[]
  }
}

export interface TransactionGetMessageData extends Action {
  type: string
  payload: {
    data: Messages[]
  }
}

export interface TransactionEditMessage extends Action {
  type: string
  payload: {
    id: string
    newData: Messages[]
  }
}

export interface TransactionDeleteMessage extends Action {
  type: string
  payload: {
    id: string
  }
}

export interface TransactionDelete extends Action {
  type: string
  payload: {
    id: string
    status: string
    newData: any
  }
}

export interface TransactionAddFee extends Action {
  type: string
  payload: {
    newData: TransactionFee[]
  }
}

export interface TransactionGetFeeData extends Action {
  type: string
  payload: {
    data: TransactionFee[]
  }
}

export interface TransactionEditFeeData extends Action {
  type: string
  payload: {
    id: string
    newData: TransactionFee[]
  }
}

export interface TransactionDeleteFeeData extends Action {
  type: string
  payload: {
    id: string
  }
}

export interface TransactionAddCredits extends Action {
  type: string
  payload: {
    newData: TransactionFee[]
  }
}

export interface TransactionGetCreditsData extends Action {
  type: string
  payload: {
    data: TransactionFee[]
  }
}

export interface TransactionEditCreditsData extends Action {
  type: string
  payload: {
    id: string
    newData: TransactionFee[]
  }
}

export interface TransactionDeleteCreditsData extends Action {
  type: string
  payload: {
    id: string
  }
}

export interface TransactionEditRecord extends Action {
  type: string
  payload: {
    newData: any
  }
}

export interface TransactionImportData extends Action {
  type: string
  payload: {
    boardId: string
    importedData: any
  }
}
export interface TransactionAddReferralFee extends Action {
  type: string
  payload: {
    newData: TransactionReferralFee[]
  }
}

export interface TransactionGetReferralFee extends Action {
  type: string
  payload: {
    data: TransactionReferralFee[]
  }
}

export interface TransactionEditReferralFee extends Action {
  type: string
  payload: {
    id: string
    newData: TransactionReferralFee[]
  }
}

export interface TransactionDeleteReferralFee extends Action {
  type: string
  payload: {
    id: string
  }
}

export interface TransactionAddNewManagerNote extends Action {
  type: string
  payload: {
    newData: ManagerNotes[]
  }
}

export interface TransactionGetManagerNotes extends Action {
  type: string
  payload: {
    data: ManagerNotes[]
  }
}

export interface TransactionEditManagerNote extends Action {
  type: string
  payload: {
    id: string
    newData: ManagerNotes[]
  }
}

export interface TransactionDeleteManagerNote extends Action {
  type: string
  payload: {
    id: string
  }
}

export interface TransactionShareWith extends Action {
  type: string
  payload: {
    shareWithObj: string
  }
}

export interface TransactionDetails extends Action {
  type: string
  payload: {
    data: string
  }
}

export interface GetTransactionShareWith extends Action {
  type: string
  payload: {
    data: string
  }
}

export interface DeleteTransactionShareWith extends Action {
  type: string
  payload: {
    data: string
  }
}

export interface GetTransactionWorkflowTask extends Action {
  type: string
  payload: {
    data: any
  }
}

export interface CreateTransactionWorkflowTask extends Action {
  type: string
  payload: {
    data: any
  }
}

export interface DeleteTransactionWorkflowTask extends Action {
  type: string
  payload: {
    data: any
  }
}

export interface UpdateTransactionWorkflowTask extends Action {
  type: string
  payload: {
    data: any
  }
}

export interface GetTransactionDocumentChecklist extends Action {
  type: string
  payload: {
    data: any
  }
}
export interface UpdateTransactionDocumentChecklist extends Action {
  type: string
  payload: {
    data: number
  }
}

export interface UpdateTransactionAllDocumentChecklist extends Action {
  type: string
  payload: {
    data: any
  }
}

export interface GetTransactionDocuments extends Action {
  type: string
  payload: {
    data: any
  }
}
export interface CreateTransactionDocuments extends Action {
  type: string
  payload: {
    data: any
  }
}
export interface TransactionAddWorkflow extends Action {
  type: string
  payload: {
    newData: TransactionWorkflow[]
  }
}

export interface TransactionGetWorkflow extends Action {
  type: string
  payload: {
    data: TransactionWorkflow[]
  }
}

export interface DeleteMultipleTransactionWorkflowTasks extends Action {
  type: string
  payload: {
    ids: any
  }
}

export interface UpdateMultipleTransactionWorkflowTasks extends Action {
  type: string
  payload: {
    ids: any
    status: string
  }
}

export interface PropertyImageAdd extends Action {
  type: string
  payload: {
    image: PropertyImageType
  }
}

export interface PropertyImagesSet extends Action {
  type: string
  payload: {
    images: PropertyImageType[]
  }
}

export interface PropertyImageDelete extends Action {
  type: string
  payload: {
    id: string
  }
}

export interface TransactionSortBoardData extends Action {
  type: string
  payload: {
    boardId: string
    sortOrder: number
  }
}

export interface TransactionCommission extends Action {
  type: string
  payload: {
    data: any
  }
}

export interface UpdateTransactionCommission extends Action {
  type: string
  payload: {
    data: any
  }
}

export interface TransactionTotalCommission extends Action {
  type: string
  payload: {
    data: any
  }
}

export interface DocumentCount extends Action {
  type: string
  payload: {
    count: number
  }
}

export interface MessageCount extends Action {
  type: string
  payload: {
    count: number
  }
}

export interface PaymentApprove extends Action {
  type: string
  payload: {
    isApproved: boolean
  }
}

export interface PaymentRelease extends Action {
  type: string
  payload: {
    isReleased: boolean
  }
}

export interface TransactionToggleNotesModal extends Action {
  type: string
  payload: {
    open: boolean
  }
}

export interface TransactionCreditDebitStatus extends Action {
  type: string
  payload: {
    accountId: string
    status: string
  }
}

export interface TransactionToggleTab extends Action {
  type: string
  payload: {
    open: boolean
  }
}

export interface TransferTransaction extends Action {
  type: string
  payload: {
    cardId: string
    destinationBoardId: string
    sourceBoardId: string
    index: number
  }
}

export interface TransactionFilter extends Action {
  type: string
  payload: {
    data: Filter
  }
}

export interface TransactionSerachLoader extends Action {
  type: string
  payload: {
    open: boolean
  }
}

export interface TransactionShowDefaultView extends Action {
  type: string
  payload: {
    open: boolean
  }
}

export interface TransactionSearchData extends Action {
  type: string
  payload: {
    data: any
  }
}

export interface TransactionShowDocumentLayout extends Action {
  type: string
  payload: {
    open: boolean
  }
}

export interface TransactionResetState extends Action {
  type: string
}

export interface TransactionEditOwnership extends Action {
  payload: {
    data: any
  }
  type: string
}

export interface TransactionOfficeFilter extends Action {
  payload: {
    data: string
  }
  type: string
}

export interface TransactionOwnerID extends Action {
  payload: {
    data: string
  }
  type: string
}

export interface UserPassTransactionFilter extends Action {
  payload: {
    filters: TransactionPassFilter
  }
  type: string
}

export interface TransactionConfetti extends Action {
  payload: {
    data: boolean
  }
  type: string
}
