const Actions = {
  changeCreditDebitStatus: 'CHANGE_CREDIT_DEBIT_STATUS',
  createTransactionDocument: 'CREATE_TRANSACTION_DOCUMENTS',
  createTransactionWorkflowTask: 'CREATE_TRANSACTION_WORKFLOW_TASK',
  deleteMultipleTransactionWorkflowTask:
    'DELETE_MULTIPLE_TRANSACTION_WORKFLOW_TASK',
  deleteTransactionShareWith: 'DELETE_TRANSACTION_SHARE_WITH',
  deleteTransactionWorkflowTask: 'DELETE_TRANSACTION_WORKFLOW_TASK',
  documentCount: 'DOCUMENT_COUNT',
  fetchAllRecentTransactions: 'FETCH_ALL_TRANSACTIONS',
  getCommission: 'GET_COMMISSION',
  getTransactionDocumentChecklist: 'GET_TRANSACTION_DOCUMENT_CHECKLIST',
  getTransactionDocuments: 'GET_TRANSACTION_DOCUMENTS',
  getTransactionShareWith: 'GET_TRANSACTION_SHARE_WITH',
  getTransactionWorkflowTask: 'GET_TRANSACTION_WORKFLOW_TASK',
  messageCount: 'MESSAGE_COUNT',
  paymentApprove: 'PAYMENT_APPROVE',
  paymentReleased: 'PAYMENT_RELEASED',
  propertyImageAdd: 'ADD_PROPERTY_IMAGE',
  propertyImageDelete: 'DELETE_PROPERTY_IMAGE',
  propertyImagesSet: 'SET_PROPERTY_IMAGES',
  resetState: 'RESET_STATE',
  totalCommission: 'TOTAL_COMMISSION',
  transactionAddCredits: 'ADD_TRANSACTION_ADd_CREDITS',
  transactionAddFee: 'ADD_TRANSACTION_FEE',
  transactionAddNewBoard: 'ADD_TRANSACTION_NEW_BOARD',
  transactionAddNewCard: 'ADD_TRANSACTION_NEW_CARD',
  transactionAddNewManagerNote: 'ADD_TRANSACTION_NEW_MANAGER_NOTE',
  transactionAddNewMessage: 'ADD_TRANSACTION_NEW_MESSAGE',
  transactionAddReferralFee: 'ADD_TRANSACTION_REFERRALFEE_FEE',
  transactionAddWorkflow: 'ADD_TRANSACTION_WORKFLOW',
  transactionChangestatus: 'CHANGE_TRANSACTION_STATUS',
  transactionConfetti: 'TRANSACTION_CONFETTI',
  transactionDelete: 'DELETE_TRANSACTION',
  transactionDeleteCard: 'DELETE_TRANSACTION_CARD',
  transactionDeleteCreditsData: 'DELETE_TRANSACTION_CREDITS_DATA',
  transactionDeleteFeeData: 'DELETE_TRANSACTION_FEE_DATA',
  transactionDeleteManagerNote: 'DELETE_TRANSACTION_MANAGER_NOTE',
  transactionDeleteMessage: 'DELETE_TRANSACTION_MESSAGE',
  transactionDeleteRecord: 'DELETE_TRANSACTION_RECORD',
  transactionDeleteReferralFee: 'DELETE_TRANSACTION_REFERRALFEE_FEE',
  transactionDetails: 'SET_TRANSACTION_DETAILS',
  transactionEditCreditsData: 'EDIT_TRANSACTION_CREDITS_DATA',
  transactionEditFeeData: 'EDIT_TRANSACTION_FEE_DATA',
  transactionEditManagerNote: 'EDIT_TRANSACTION_MANAGER_NOTE',
  transactionEditMessage: 'EDIT_TRANSACTION_MESSAGE',
  transactionEditOwnership: 'EDIT_TRANSACTION_OWNERSHIP',
  transactionEditRecord: 'EDIT_TRANSACTION_RECORD',
  transactionEditReferralFee: 'EDIT_TRANSACTION_REFERRALFEE_FEE',
  transactionFilter: 'TRANSACTION_FILTER',
  transactionGetBoardData: 'GET_TRANSACTION_BOARD_DATA',
  transactionGetCreditsData: 'GET_TRANSACTION_CREDITS_DATA',
  transactionGetFeeData: 'GET_TRANSACTION_FEE_DATA',
  transactionGetManagerNotes: 'GET_TRANSACTION_MANAGER_NOTES',
  transactionGetMessageData: 'GET_TRANSACTION_MESSAGE_DATA',
  transactionGetReferralFee: 'GET_TRANSACTION_REFERRALFEE_FEE',
  transactionGetWorkflow: 'GET_TRANSACTION_WORKFLOW',
  transactionImportData: 'TRANSACTION_IMPORT_DATA',
  transactionModal: 'TRANSACTION_STATUS',
  transactionOfficeFilter: 'EDIT_TRANSACTION_OFFICE_FILTER',
  transactionOwnerID: 'EDIT_TRANSACTION_OWNER_ID',
  transactionPageNumber: 'TRANSACTION_PAGE_NUMBER',
  transactionResetCardData: 'RESET_TRANSACTION_CARD_DATA',
  transactionRestoreCard: 'RESTORE_TRANSACTION_CARD',
  transactionSearchData: 'TRANSACTION_SEARCH_DATA',
  transactionSearchLoader: 'TRANSACTION_SEARCH_LOADER',
  transactionSetNewBoardName: 'SET_TRANSACTION_NEW_BOARD_NAME',
  transactionSetNewDescription: 'SET_TRANSACTION_NEW_DESCRIPTION',
  transactionSetNewIcon: 'SET_TRANSACTION_NEW_ICON',
  transactionShareWith: 'SHARE_TRANSACTION_WITH',
  transactionShowDefaultView: 'TRANSACTION_SHOW_DEFAULT_VIEW',
  transactionShowDocumentLayout: 'TRANSACTION_SHOW_DOCUMENT_LAYOUT',
  transactionSortBoardData: 'SORT_TRANSACTION_BOARD_DATA',
  transactionTab: 'TRANSACTION_TAB',
  transferTransaction: 'TRANSFER_TRANSACTION',
  updateCommission: 'UPDATE_COMMISSION',
  updateMultipleTransactionWorkflowTask:
    'UPDATE_MULTIPLE_TRANSACTION_WORKFLOW_TASK',
  updateTransactionAllDocumentChecklist:
    'UPDATE_TRANSACTION_ALL_DOCUMENT_CHECKLIST',
  updateTransactionDocumentChecklist: 'UPDATE_TRANSACTION_DOCUMENT_CHECKLIST',
  updateTransactionWorkflowTask: 'UPDATE_TRANSACTION_WORKFLOW_TASK',
  userPassTransactionFilter: 'USER_PASS_TRANSACTION_FILTER'
}

export default Actions
