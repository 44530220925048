// Import Packages
import { sortBy } from 'lodash'
import ConfirmAlert from 'sweetalert2'

// Import Components
import client from 'queries/apollo'
import { formatListData, reformatData, reformatListData } from './Utils/FormattingData'

import { TeamItemsDetails } from 'store/Teams/Types'

// Import Graphql Mutations
import {
  CREATE_TEAM,
  DELETE_TEAM,
  MOVE_TEAM,
  UPDATE_TEAM,
  UPDATE_TEAM_LEADER,
  UPDATE_TEAM_OFFICE_ORDER,
  UPDATE_TEAM_ORDER
} from 'queries/graphql/Teams/Mutation'

// Import Graphql Queries
import { GET_OFFICES_TEAMS, GET_TEAMS } from 'queries/graphql/Teams/Queries'
import { getOptions } from './TeamsQueries'

// Import Utils
import { Strings } from '../../utils'

export const deleteTeam = async (cardId: string) => {
  const deleteTeamId: any = []
  deleteTeamId.push(cardId)
  await client.mutate({
    mutation: DELETE_TEAM,
    refetchQueries: [
      { query: GET_TEAMS, variables: { isActive: true } },
      {
        query: GET_OFFICES_TEAMS,
        variables: { sortOrder: 1, orderField: 'teamOfficeOrder' }
      }
    ],
    variables: { teamId: deleteTeamId }
  })
  return getOptions()
}

export const restore = async (cardId: string) => {
  await client.mutate({
    mutation: UPDATE_TEAM,
    refetchQueries: [
      {
        query: GET_OFFICES_TEAMS,
        variables: { sortOrder: 1, orderField: 'teamOfficeOrder' }
      },
      { query: GET_TEAMS, variables: { isActive: true } }
    ],
    variables: { teamId: cardId, isActive: true }
  })
  return getOptions()
}

export const reorderCard = (boardId: string, cardId: string, sourceIndex: number, destinationIndex: number) => {
  client.mutate({
    mutation: UPDATE_TEAM_ORDER,
    refetchQueries: [
      {
        query: GET_OFFICES_TEAMS,
        variables: { sortOrder: 1, orderField: 'teamOfficeOrder' }
      },
      { query: GET_TEAMS, variables: { isActive: true } }
    ],
    variables: {
      board: boardId,
      cardId: cardId,
      destination: destinationIndex,
      source: sourceIndex
    }
  })
}

export const reorderLane = (boardId: string, sourceIndex: number, destinationIndex: number) => {
  client.mutate({
    mutation: UPDATE_TEAM_OFFICE_ORDER,
    refetchQueries: [
      {
        query: GET_OFFICES_TEAMS,
        variables: { sortOrder: 1, orderField: 'teamOfficeOrder' }
      },
      { query: GET_TEAMS, variables: { isActive: true } }
    ],
    variables: {
      board: boardId,
      destination: destinationIndex,
      source: sourceIndex
    }
  })
}

export const deleteCard = async (cardId: string) => {
  const deleteTeamId: any = []
  deleteTeamId.push(cardId)
  client.mutate({
    mutation: DELETE_TEAM,
    refetchQueries: [
      {
        query: GET_OFFICES_TEAMS,
        variables: { sortOrder: 1, orderField: 'teamOfficeOrder' }
      },
      { query: GET_TEAMS, variables: { isActive: true } }
    ],
    variables: { teamId: deleteTeamId }
  })
}

export const addNewTeam = (
  newTeamData: TeamItemsDetails,
  boardId: string,
  addTeamIndex: number,
  show: boolean,
  edit: boolean
) => {
  const { teamName, leaderName, office, _id, teamCap: { residentialTargetCap, commercialTargetCap } } = newTeamData
  if (show) {
    return client
      .mutate({
        mutation: CREATE_TEAM,
        refetchQueries: [
          {
            query: GET_OFFICES_TEAMS,
            variables: { sortOrder: 1, orderField: 'teamOfficeOrder' }
          },
          { query: GET_TEAMS, variables: { isActive: true } }
        ],
        update: (proxy: any, { data }: any) => {
          const proxyData: any = proxy.readQuery({
            query: GET_OFFICES_TEAMS,
            variables: { sortOrder: 1, orderField: 'teamOfficeOrder' }
          })
          const newdata = reformatData(proxyData)
          const listColumn = newdata.getOfficeTeams.find((list: any) => {
            return list._id === boardId
          })
          data.createTeam.users = Object.assign([])
          data.createTeam.teamOrder = addTeamIndex
          listColumn.teams.push(data.createTeam)
          const addedData = newdata.getOfficeTeams.map((list: any) => {
            if (list._id === listColumn._id) {
              return listColumn
            }
            return list
          })
          sortBy(addedData.offices, ['teamOfficeOrder'])
          proxy.writeQuery({
            data: { getOfficeTeams: addedData },
            query: GET_OFFICES_TEAMS,
            variables: { sortOrder: 1, orderField: 'teamOfficeOrder' }
          })
        },
        variables: {
          leader: leaderName,
          name: teamName,
          office: boardId,
          teamOrder: addTeamIndex
        }
      })
      .then(() => {
        return getOptions()
      })
  } else {
    if (edit) {
      return client
        .mutate({
          mutation: UPDATE_TEAM,
          refetchQueries: [
            { query: GET_TEAMS, variables: { isActive: true } },
            {
              query: GET_OFFICES_TEAMS,
              variables: { sortOrder: 1, orderField: 'teamOfficeOrder' }
            }
          ],
          update: (proxy: any, { data }: any) => {
            const proxyData: any = proxy.readQuery({
              query: GET_TEAMS,
              variables: { isActive: true }
            })
            const newdata = reformatListData(proxyData)
            const adddata = newdata.map((team: any) => {
              if (team._id === _id) {
                return newTeamData
              }
              return team
            })
            const ndata = formatListData(adddata)
            proxy.writeQuery({
              data: { getTeams: ndata },
              query: GET_TEAMS,
              variables: { isActive: true }
            })
          },
          variables: { teamId: _id, name: teamName, residentialTargetCap, commercialTargetCap }
        })
        .then(() => {
          return client.mutate({
            mutation: MOVE_TEAM,
            refetchQueries: [
              {
                query: GET_OFFICES_TEAMS,
                variables: { sortOrder: 1, orderField: 'teamOfficeOrder' }
              },
              { query: GET_TEAMS, variables: { isActive: true } }
            ],
            variables: { office: office._id, teamId: _id }
          })
        })
        .then((response: any) => {
          return client.mutate({
            mutation: UPDATE_TEAM_LEADER,
            refetchQueries: [
              {
                query: GET_OFFICES_TEAMS,
                variables: { sortOrder: 1, orderField: 'teamOfficeOrder' }
              },
              { query: GET_TEAMS, variables: { isActive: true } }
            ],
            variables: { teamId: _id, leaderId: leaderName }
          })
        })
        .then(() => {
          return getOptions()
        })
    } else {
      return client
        .mutate({
          mutation: CREATE_TEAM,
          refetchQueries: [
            { query: GET_TEAMS, variables: { isActive: true } },
            {
              query: GET_OFFICES_TEAMS,
              variables: { sortOrder: 1, orderField: 'teamOfficeOrder' }
            }
          ],
          update: (proxy: any, { data }: any) => {
            const proxyData: any = proxy.readQuery({
              query: GET_TEAMS,
              variables: { isActive: true }
            })
            const newdata = reformatListData(proxyData)
            data.createTeam.members = Object.assign([])
            newdata.push(data.createTeam)
            proxy.writeQuery({
              data: { getTeams: newdata },
              query: GET_TEAMS,
              variables: { isActive: true }
            })
          },
          variables: {
            leader: leaderName,
            name: teamName,
            office: office._id,
            teamOrder: addTeamIndex
          }
        })
        .then(() => {
          return getOptions()
        })
    }
  }
}

export const deleteTeams = async () => {
  let res
  const teamData = await client.query({
    query: GET_TEAMS,
    variables: { isActive: true }
  })
  const { data }: any = teamData
  const teamIds: any = []
  data.getTeams.forEach((team: any) => {
    if (team.checked === true) {
      teamIds.push(team._id)
    }
  })
  if (teamIds.length > 0) {
    ConfirmAlert({
      cancelButtonText: Strings.kanbanView.noKeepIt,
      confirmButtonText: Strings.kanbanView.yesDeleteIt,
      showCancelButton: true,
      text: 'Remove selected team(s)',
      title: Strings.kanbanView.sure,
      type: 'warning'
    }).then((result: any) => {
      if (result.value) {
        client.mutate({
          mutation: DELETE_TEAM,
          refetchQueries: [
            { query: GET_TEAMS, variables: { isActive: true } },
            {
              query: GET_OFFICES_TEAMS,
              variables: { sortOrder: 1, orderField: 'teamOfficeOrder' }
            }
          ],
          variables: { teamId: teamIds }
        })
        ConfirmAlert(Strings.kanbanView.deleted, 'Your team(s) has been deleted', 'success')
        res = 1
      } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
        ConfirmAlert(Strings.kanbanView.cancelled, 'Your team(s) is safe', 'error')
        res = 0
      }
    })
  } else {
    ConfirmAlert(Strings.kanbanView.error, Strings.teams.errorTeamText, 'error')
  }
  return res
}

export const moveTeams = (cardId: string, destinationIndex: number, boardId: string) => {
  return client
    .mutate({
      mutation: MOVE_TEAM,
      refetchQueries: [
        {
          query: GET_OFFICES_TEAMS,
          variables: { sortOrder: 1, orderField: 'teamOfficeOrder' }
        },
        { query: GET_TEAMS, variables: { isActive: true } }
      ],
      variables: {
        destinationOrder: destinationIndex,
        office: boardId,
        teamId: cardId
      }
    })
    .then(() => {
      return getOptions()
    })
}
