// Import Packages
import * as React from 'react'

import {
  SalesGoalsModal
} from '../../../Modals'

import { UserProfileCounters, UserProfileType } from 'store/UserProfile/Types'

// Import Utils
import { getLoggedInUser, Strings } from 'utils'

// Import Styled Components
import {
  Container,
  StyledCard,
} from './Styled'

// Font Awesome Icons
import {
  faBuilding,
  faBullhorn,
  faFolders,
  faHome,
  faPencilAlt,
  faUser,
  faUsers,
} from '@fortawesome/pro-light-svg-icons'
import {
  faArrowDown,
  faArrowUp,
  faDollarSign,
  faPercent,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faDollarSign, faArrowUp, faBullhorn, faFolders, faArrowDown, faUser, faPercent, faBuilding, faUsers, faHome, faPencilAlt])

interface UserType {
  _id: string
  role: string
  profileImage: string
}

interface Props {
  openReferralPopup: () => void
  openSalesGoalsPopup: () => void
  userProfile: UserProfileType
  userProfileCounter: UserProfileCounters
}

interface State {
  commercialCapPercentage: number
  residentialCapPercentage: number
  showSalesGoals: boolean
  salesGoal: number
  capStatus: string
  user: UserType
}

export default class Cards extends React.Component<Props, State> {
  public state = {
    capStatus: '',
    commercialCapPercentage: 0,
    residentialCapPercentage: 0,
    salesGoal: 0,
    showSalesGoals: false,
    user: {} as UserType
  }

  public getItems = () => {
    return [
      {
        className: 'sales-card',
        header: <div className='sales-header' ><FontAwesomeIcon icon={['fal', 'bullhorn']} /><span>{Strings.userProfile.graphSection.sales}</span><span style={{ marginLeft: 30 }} onClick={() => this.openSalesGoalsPopup()}><FontAwesomeIcon icon={['fal', 'pencil-alt']} /></span></div>,
        key: 0,
        meta: <div className='sales'><div>{this.props.userProfileCounter[`salesPercentage`] !== null ? parseFloat(this.props.userProfileCounter[`salesPercentage`]).toFixed(2) : 0}<FontAwesomeIcon icon={['far', 'percent']} /></div><div>${this.state.salesGoal.toLocaleString()}</div></div>,
      },
      {
        className: 'transaction-card',
        header: <div className='transaction-header'><FontAwesomeIcon icon={['fal', 'folders']} /><span>{Strings.userProfile.graphSection.transactions}</span></div>,
        key: 1,
        meta: <div className='transaction' ><div>{this.props.userProfileCounter[`totalTransactions`]}</div><div>NEW</div></div>,
      },
      {
        className: 'lead-card',
        header: <div className='lead-header'><FontAwesomeIcon icon={['fal', 'user']} /><span>{Strings.userProfile.graphSection.revenue}</span></div>,
        key: 2,
        meta: <div className='sales' ><FontAwesomeIcon icon={['far', 'dollar-sign']} /><span>{parseFloat(this.props.userProfileCounter[`totalRevenue`]).toFixed(2)}</span></div>,
      },
      {
        className: 'sales-card',
        header: <div className='sales-header'><FontAwesomeIcon icon={['fal', 'home']} /><span>{Strings.userProfile.graphSection.residentialCAP}</span></div>,
        key: 3,
        meta: <div className='sales' style={{ marginTop: 6 }}>{<React.Fragment><div>{this.props.userProfileCounter[`resPercentage`] !== null ? parseFloat(this.props.userProfileCounter[`resPercentage`]).toFixed(2) : 0}<FontAwesomeIcon icon={['far', 'percent']} /></div><div>${this.props.userProfile.userCap.residentialCompletedCap}/${this.props.userProfile.userCap.residentialTargetCap}</div></React.Fragment>}</div>,
      },
      {
        className: 'transaction-card',
        header: <div className='sales-header'><FontAwesomeIcon icon={['fal', 'building']} /><span>{Strings.userProfile.graphSection.commercialCAP}</span></div>,
        key: 4,
        meta: <div className='sales' style={{ marginTop: 3 }}>{<React.Fragment><div>{this.props.userProfileCounter[`comPercentage`] !== null ? parseFloat(this.props.userProfileCounter[`comPercentage`]).toFixed(2) : 0}<FontAwesomeIcon icon={['far', 'percent']} /></div><div>${this.props.userProfile.userCap.commercialCompletedCap}/${this.props.userProfile.userCap.commercialTargetCap}</div></React.Fragment>}</div>,
      }
    ]
  }

  public getItemsForCapOff = () => {
    return [
      {
        className: 'sales-card',
        header: <div className='sales-header' ><FontAwesomeIcon icon={['fal', 'bullhorn']} /><span>{Strings.userProfile.graphSection.sales}</span><span style={{ marginLeft: 30 }} onClick={() => this.openSalesGoalsPopup()}><FontAwesomeIcon icon={['fal', 'pencil-alt']} /></span></div>,
        key: 0,
        meta: <div className='sales'><div>{this.props.userProfileCounter[`salesPercentage`] !== null ? parseFloat(this.props.userProfileCounter[`salesPercentage`]).toFixed(2) : 0}<FontAwesomeIcon icon={['far', 'percent']} /></div><div>${this.state.salesGoal.toLocaleString()}</div></div>,
      },
      {
        className: 'transaction-card',
        header: <div className='transaction-header'><FontAwesomeIcon icon={['fal', 'folders']} /><span>{Strings.userProfile.graphSection.transactions}</span></div>,
        key: 1,
        meta: <div className='transaction' ><div>{this.props.userProfileCounter[`totalTransactions`]}</div><div>NEW</div></div>,
      },
      {
        className: 'lead-card',
        header: <div className='lead-header'><FontAwesomeIcon icon={['fal', 'user']} /><span>{Strings.userProfile.graphSection.revenue}</span></div>,
        key: 2,
        meta: <div className='sales' ><FontAwesomeIcon icon={['far', 'dollar-sign']} /><span>{parseFloat(this.props.userProfileCounter[`totalRevenue`]).toFixed(2)}</span></div>,
      }
    ]
  }

  public getItemsForGuest = () => {
    return [
      {
        className: 'sales-card',
        header: <div className='sales-header' ><FontAwesomeIcon icon={['fal', 'bullhorn']} /><span>{Strings.userProfile.graphSection.sales}</span></div>,
        key: 0,
        meta: <div className='sales'><div>N/A</div><div>N/A</div></div>,
      },
      {
        className: 'transaction-card',
        header: <div className='transaction-header'><FontAwesomeIcon icon={['fal', 'folders']} /><span>{Strings.userProfile.graphSection.transactions}</span></div>,
        key: 1,
        meta: <div className='transaction' ><div>N/A</div><div>NEW</div></div>,
      },
      {
        className: 'lead-card',
        header: <div className='lead-header'><FontAwesomeIcon icon={['fal', 'user']} /><span>{Strings.userProfile.graphSection.revenue}</span></div>,
        key: 2,
        meta: <div className='sales'><span>N/A</span></div>,
      },
      {
        className: 'sales-card',
        header: <div className='sales-header'><FontAwesomeIcon icon={['fal', 'home']} /><span>{Strings.userProfile.graphSection.residentialCAP}</span></div>,
        key: 3,
        meta: <div className='sales' style={{ marginTop: 6 }}><div>N/A</div><div>N/A</div></div>,
      },
      {
        className: 'transaction-card',
        header: <div className='sales-header'><FontAwesomeIcon icon={['fal', 'building']} /><span>{Strings.userProfile.graphSection.commercialCAP}</span></div>,
        key: 4,
        meta: <div className='sales' style={{ marginTop: 3 }}><div>N/A</div><div>N/A</div></div>,
      }
    ]
  }

  public async componentDidMount() {
    const { userProfile } = this.props
    const user: any = await getLoggedInUser({ fromCache: true })
    const residentialCapPercentage = userProfile.userCap.residentialCompletedCap >= 0 ? (userProfile.userCap.residentialCompletedCap / userProfile.userCap.residentialTargetCap) * 100 : 0
    const commercialCapPercentage = userProfile.userCap.commercialCompletedCap >= 0 ? (userProfile.userCap.commercialCompletedCap / userProfile.userCap.commercialTargetCap) * 100 : 0
    this.setState({
      capStatus: userProfile.capSetting.capStatus,
      commercialCapPercentage,
      residentialCapPercentage,
      salesGoal: userProfile.salesGoal,
      user
    })
  }

  public render() {
    const { showSalesGoals, user, capStatus } = this.state
    return (
      <Container>
        {
          showSalesGoals && <SalesGoalsModal handleClose={this.closeSalesGoalsPopup} />
        }
        {
          user.role === 'GUEST' ? <StyledCard items={this.getItemsForGuest()} /> : capStatus === 'ON' ? <StyledCard items={this.getItems()} /> : <StyledCard items={this.getItemsForCapOff()} />
        }
      </Container>
    )
  }

  public openSalesGoalsPopup = () => { this.setState({ showSalesGoals: true }) }

  public closeSalesGoalsPopup = (salesGoal: number) => { this.setState({ showSalesGoals: false, salesGoal: salesGoal !== undefined ? salesGoal : this.state.salesGoal }) }
}
