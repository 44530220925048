import * as React from 'react'

import Passes from './Passes'
import Welcome from './Welcome'

import { getLoggedInUser } from 'utils'

import { Container } from './Styled'

import { UserType } from './Types'

interface State {
  user: UserType
}

class MainContent extends React.Component<{}, State> {
  public state = {
    user: {} as UserType
  }

  public componentDidMount = async () => {
    const user = await getLoggedInUser({ fromCache: true }) || {} as UserType
    this.setState({ user })
  }

  public render() {
    const { user } = this.state
    return (
      <Container>
        {user.role !== 'GUEST' && (
          <Passes />
        )}
        <Welcome />
      </Container>
    )
  }
}

export default MainContent
