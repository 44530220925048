// Import Packages
import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'

// Import Images and Icons
import SampleBG from 'design/images/dashboard/Back7.jpg'
import { getLoggedInUser } from 'utils'

// Import Components
import QuickViewModal from 'app/Transactions/QuickViewModal'
import MainContent from './MainContent'
import SidePanel from './SidePanel'

// Import Store Types, Actions and Reducers
import { AppState } from 'store/CombineReducers'
import * as DashboardActions from 'store/Dashboard/Actions'
import * as transactionOperations from 'store/Transactions/Actions'
import { Transaction } from 'store/Transactions/Types'
import { UserType } from './Types'

import { getUserWallpaper } from '../Navbar/Menu/Customize/CustomizeQueries'
import { getTransactionBoardsDashboard, getTransactions } from 'app/Transactions/Dashboard/TransactionQueries'
import { addTransactionsToBoards, formatKanbanData, formatListData } from '../Transactions/Dashboard/Utils/FormattingData'

// Import Styled Components
import { Container, StyledGrid } from './Styled'

interface StoreProps {
  transactions: Transaction[]
  detailsPopup: {
    open: boolean
    id: string
  }
  eventsPopup: {
    open: boolean
  }
  widgetsPopup: {
    open: boolean
  }
  setThemeImage: (image: string) => void
  setCustomeColor: (color: string) => void
  setAllRecentTransactions: (allTransactions: Transaction[], allListTransactions: any) => void
  setToggleDetailsPopup: ({ open, id }: { open: boolean; id: string }) => void
  setToggleEventsPopUp: ({ open }: { open: boolean }) => void
  setToggleAllWidgets: (payload: { open: false }) => void
  setUserProfile: (profile: string) => void
  setTransactionDetail: (data: any) => void
  setResetState: () => void
  themeImage: string
  customeColor: string
  toggleTab: boolean
}

type Props = StoreProps & RouteComponentProps<{}, {}>

interface State {
  toggleSearchPass: boolean
  user: UserType
}

export let ToggleSearchPass: any
export let SetThemeImage: any
export let SetCustomeColor: any

class Dashboard extends React.Component<Props, State> {
  public setIntervalForGetTransactions: any = null

  public state = {
    toggleSearchPass: false,
    user: {} as UserType
  }

  public async componentDidMount() {
    const { setThemeImage, setCustomeColor } = this.props
    const user: UserType = await getLoggedInUser({ fromCache: true }) || {} as UserType
    this.setState({ user }, () => {
      this.refreshGetTransactions()
    })
    const response = await getUserWallpaper()
    setThemeImage(response.defaultUrl)
    setCustomeColor(response.defaultColor)
    this.props.setUserProfile(user.profileImage)
    this.assignToggleSearchPass()
    this.assignSetThemeImage()
    this.assignSetCustomeColor()
    // this.setIntervalForGetTransactions = window.setInterval(this.refreshGetTransactions, 10000)
  }

  public refreshGetTransactions = async () => {
    const { setAllRecentTransactions } = this.props
    const { user } = this.state
    const transactions = await getTransactions({ skip: 0, limit: 20 })
    let transactionBoards = await getTransactionBoardsDashboard()
    if (user.role === 'AGENT') {
      transactionBoards = addTransactionsToBoards(transactions, transactionBoards)
    }
    const transactionKanbanData = await formatKanbanData(transactionBoards)
    const transactionListData = await formatListData(transactions)
    setAllRecentTransactions(transactionKanbanData, transactionListData)
  }

  public componentWillUnmount() {
    this.props.setToggleDetailsPopup({ open: false, id: '' })
    this.props.setToggleEventsPopUp({ open: false })
    clearInterval(this.setIntervalForGetTransactions)
  }

  public render() {
    const { detailsPopup, themeImage, customeColor, transactions, toggleTab } = this.props
    return (
      <Container>
        {toggleTab && <div className={'block'} />}
        {detailsPopup.open && <QuickViewModal transactionId={detailsPopup.id} onClose={this.closeModal} />}
        {transactions.length > 0 && (
          <StyledGrid celled={true} background={themeImage} color={customeColor}>
            <Grid.Row>
              <Grid.Column width={4}>
                <SidePanel />
              </Grid.Column>
              <Grid.Column width={12}>
                <MainContent />
              </Grid.Column>
            </Grid.Row>
            {themeImage ? (
              <img className="wallpaperContainer" src={themeImage} alt="wallpaper" />
            ) : (
                <img className="wallpaperContainer" src={SampleBG} alt="wallpaper" />
              )}
          </StyledGrid>
        )}
      </Container>
    )
  }

  private assignSetThemeImage = () => {
    SetThemeImage = (image: string) => {
      const { setThemeImage } = this.props
      setThemeImage(image)
    }
  }

  private assignSetCustomeColor = () => {
    SetCustomeColor = (color: string) => {
      const { setCustomeColor } = this.props
      setCustomeColor(color)
    }
  }

  private assignToggleSearchPass = () => {
    ToggleSearchPass = () => {
      this.setState({ toggleSearchPass: true })
    }
  }

  private closeModal = () => {
    const { setTransactionDetail, setResetState } = this.props
    setTransactionDetail({})
    this.setState({ toggleSearchPass: false })
    this.props.setToggleDetailsPopup({ open: false, id: '' })
    this.props.setToggleAllWidgets({ open: false })
    setResetState()
  }
}

const mapStateToProps = (state: AppState) => ({
  customeColor: state.dashboard.customeColor,
  detailsPopup: state.dashboard.detailsPopup,
  eventsPopup: state.dashboard.eventsPopup,
  themeImage: state.dashboard.themeImage,
  toggleTab: state.transactions.toggleTab,
  transactions: state.transactions.allTransactions,
  userProfile: state.dashboard.userProfile,
  widgetsPopup: state.dashboard.widgetsPopup
})

export default withRouter(
  connect(
    mapStateToProps,
    {
      setAllRecentTransactions: transactionOperations.getAllRecentTransactions,
      setCustomeColor: DashboardActions.setCustomeColor,
      setResetState: transactionOperations.resetState,
      setThemeImage: DashboardActions.setThemeImage,
      setToggleAllWidgets: DashboardActions.toggleWidgetsPopup,
      setToggleDetailsPopup: DashboardActions.toggleDetailsPopup,
      setToggleEventsPopUp: DashboardActions.toggleEventsPopup,
      setTransactionDetail: transactionOperations.getTransactionDetail,
      setUserProfile: DashboardActions.getUserProfile
    }
  )(Dashboard)
)
