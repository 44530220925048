import gql from 'graphql-tag'

export const UPDATE_CAP_SETTING = gql`
  mutation updateCapSetting(
    $_id: String
    $capStatus: String
    $individualResidentialCap: Int
    $individualCommercialCap: Int
    $teamCap: Int
    $additionalMemberCap: Int
  ) {
    updateCapSetting(
      _id: $_id
      input: {
        capStatus: $capStatus
        individualResidentialCap: $individualResidentialCap
        individualCommercialCap: $individualCommercialCap
        teamCap: $teamCap
        additionalMemberCap: $additionalMemberCap
      }
    ) {
      _id
      capStatus
      individualResidentialCap
      individualCommercialCap
      teamCap
      additionalMemberCap
    }
  }
`
