// Import Packages
import moment from 'moment'
import * as React from 'react'
import { connect } from 'react-redux'
import { Grid, Icon, Table } from 'semantic-ui-react'
import ConfirmAlert from 'sweetalert2'
import { VictoryPie } from 'victory'

// Import Components
import client from 'queries/apollo'

// Import Graphql Mutations
import { getCapSettings } from 'app/Settings/TransactionFee/TransactionFeeDetails/TransactionFeeQueries'
import { DELETE_INVITE_MEMBER } from 'queries/graphql/Teams/Mutation'
import { GET_OFFICES_TEAMS, GET_TEAMS } from 'queries/graphql/Teams/Queries'
import { UPDATE_USER } from 'queries/graphql/Users/Mutation'
import * as Actions from 'store/Teams/Actions'
import { TeamDetails } from 'store/Teams/Types'
import { formatKanbanData } from '../../Utils/FormattingData'

// Import Graphql Queries
import { GET_TEAMS_DETAILS } from 'queries/graphql/Teams/Queries'

// Import Utils
import { Strings } from 'utils'

// Import Colors
import Colors from 'design/Colors'

import {
  Agreement,
  Before,
  CommercialCAP,
  Completed,
  CompletedText,
  Container,
  Contribution,
  CreatedOn,
  Delete,
  Details,
  EditBoardName,
  FileName,
  ImageContainer,
  Images,
  Lead,
  Leader,
  LeaderName,
  MemberName,
  Office,
  Percentage,
  ProgressContainer,
  Propfile,
  ResidentialCAP,
  Since,
  StyledCell,
  StyledColumn,
  StyledInput,
  StyledPopup,
  StyledProgress,
  StyledTable,
  StyledTeamGrid,
  TeamCAP,
  TeamCAPTitle,
  TeamName,
  TeamSection,
  Text,
  Title
} from './Styled'

// Font Awesome Icons
import { faDollarSign } from '@fortawesome/pro-regular-svg-icons'
import { faCircle, faPencilAlt } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GET_PRE_SIGNED_URL } from 'queries/graphql/Users/Queries'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faDollarSign, faPencilAlt, faCircle])

interface StoreProps {
  setBoardData: (data: TeamDetails[]) => void
  cardId: string
}

interface Owner {
  cardId: string
}

type Props = StoreProps & Owner

interface State {
  commission: string
  teamAgreementName: string
  teamAgreement: string
  showDetails?: number
  teamDetails: TeamDetails
  showInputBox?: boolean
  preSignedUrl: string
  capStatus: string
}

class Team extends React.Component<Props, State> {
  public state = {
    capStatus: '',
    commission: '0',
    preSignedUrl:
      'https://onechimp.s3.amazonaws.com/team_agreements/1551165628136_Screenshot%20from%202019-02-21%2013-24-49.png?AWSAccessKeyId=AKIAJX3IMR4CU4BOS4MQ&Expires=1551776006&Signature=yTGNyQ9lCey9%2F7gnmF0F8Pv9npM%3D',
    showDetails: 0,
    showInputBox: false,
    teamAgreement: '',
    teamAgreementName: '',
    teamDetails: {} as TeamDetails
  }

  public async componentDidMount() {
    const res = await client.query({ query: GET_TEAMS_DETAILS, variables: { isActive: true, id: this.props.cardId } })
    const capStatus = await getCapSettings()
    const { data }: any = res
    this.setState({ teamDetails: data.getTeams[0], capStatus })
    if (data.getTeams[0].members.length > 0) {
      let url: any = ''
      if (data.getTeams[0].members[this.state.showDetails].teamAgreement) {
        url = await client.query({
          query: GET_PRE_SIGNED_URL,
          variables: { url: data.getTeams[0].members[this.state.showDetails].teamAgreement }
        })
      }
      const i = data.getTeams[0].members[this.state.showDetails].teamAgreement
        ? data.getTeams[0].members[this.state.showDetails].teamAgreement.lastIndexOf('/')
        : -1
      const teamFile =
        data.getTeams[0].members[this.state.showDetails].teamAgreement && i >= 0
          ? data.getTeams[0].members[this.state.showDetails].teamAgreement.substring(i + 1)
          : 'No Agreement Found'
      const value =
        data.getTeams[0].members[this.state.showDetails].teamAgreement && i >= 0 ? teamFile.indexOf('_') : -1
      this.setState({
        commission: data.getTeams[0].members[this.state.showDetails].teamCommissionSplit,
        preSignedUrl: data.getTeams[0].members[this.state.showDetails].teamAgreement
          ? url.data.preSignedUrl
          : this.state.preSignedUrl,
        teamAgreement: data.getTeams[0].members[this.state.showDetails].teamAgreement
          ? data.getTeams[0].members[this.state.showDetails].teamCommissionSplit
          : 'null',
        teamAgreementName:
          data.getTeams[0].members[this.state.showDetails].teamAgreement && value >= 0
            ? data.getTeams[0].members[this.state.showDetails].teamAgreement.substring(value + 1)
            : 'No Agreement Found'
      })
    }
  }

  public getDataResidential = (percent: number) => {
    return [{ x: 1, y: percent, label: '' }, { x: 2, y: 100 - percent, label: '' }]
  }

  public getDataCommercial = (percent: number) => {
    return [{ x: 1, y: percent, label: '' }, { x: 2, y: 100 - percent, label: '' }]
  }

  public deleteUser = (userId: string) => {
    const { cardId, setBoardData } = this.props
    ConfirmAlert({
      cancelButtonText: Strings.kanbanView.noKeepIt,
      confirmButtonText: Strings.kanbanView.yesDeleteIt,
      showCancelButton: true,
      text: Strings.teams.recoverInvitation,
      title: Strings.teams.cancelInvitation,
      type: 'warning'
    }).then(async result => {
      if (result.value) {
        ConfirmAlert(Strings.kanbanView.deleted, Strings.teams.deleteInvitation, 'success')
        client.mutate({
          mutation: DELETE_INVITE_MEMBER,
          refetchQueries: [
            { query: GET_OFFICES_TEAMS, variables: { sortOrder: 1, orderField: 'teamOfficeOrder' } },
            { query: GET_TEAMS, variables: { isActive: true } }
          ],
          variables: { teamId: cardId, userId }
        })
        this.deleteInvitesUser(userId)
        const response = await client.query({
          fetchPolicy: 'network-only',
          query: GET_OFFICES_TEAMS,
          variables: { sortOrder: 1, orderField: 'teamOfficeOrder' }
        })
        const kanbanData = formatKanbanData(response.data.getOfficeTeams, false)
        setBoardData(kanbanData)
      } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
        ConfirmAlert(Strings.kanbanView.cancelled, Strings.teams.safeInvitation, 'error')
      }
    })
  }

  public deleteInvitesUser = (userId: string) => {
    const { teamDetails } = this.state
    if (Object.keys(teamDetails).length > 0) {
      const { members }: any = teamDetails
      const feeData = members.slice()
      const listIndex = feeData.findIndex((laneItem: any) => {
        return laneItem._id === userId
      })
      feeData.splice(listIndex, 1)
      teamDetails['members'] = feeData
      this.setState({ teamDetails, showDetails: 0 })
    }
  }

  public render() {
    const {
      showDetails,
      teamDetails,
      commission,
      showInputBox,
      teamAgreementName,
      preSignedUrl,
      capStatus
    } = this.state
    if (Object.keys(teamDetails).length > 0) {
      let member: any = []
      let residentialPercent: number = 0
      let commercialPercent: number = 0
      let teamCapTotal: number = 0
      if (teamDetails[`teamCap`]) {
        const { teamCap }: any = teamDetails
        teamCapTotal = teamCap.commercialTargetCap + teamCap.residentialTargetCap
        residentialPercent =
          teamCap.residentialCompletedCap > 0 ? (teamCap.residentialCompletedCap * 100) / teamCapTotal : 0
        commercialPercent =
          teamCap.commercialCompletedCap > 0 ? (teamCap.commercialCompletedCap * 100) / teamCapTotal : 0
      }
      const { members, leader }: any = teamDetails
      const membersFilters = members.filter((items: any) => {
        return items.status === 'Active'
      })
      const exists = membersFilters.find((options: any) => {
        return options.userName === leader.userName
      })
      if (!exists) {
        membersFilters.unshift(leader)
      }
      if (membersFilters.length > 0) {
        member = membersFilters.map((items: any, index: number) => {
          const showDetailsFunction = async () => {
            let url: any = ''
            if (membersFilters[index].teamAgreement) {
              url = await client.query({
                query: GET_PRE_SIGNED_URL,
                variables: { url: membersFilters[index].teamAgreement }
              })
            }
            const i = membersFilters[index].teamAgreement ? membersFilters[index].teamAgreement.lastIndexOf('/') : -1
            const teamFile =
              membersFilters[index].teamAgreement && i >= 0
                ? membersFilters[index].teamAgreement.substring(i + 1)
                : 'No Agreement Found'
            const value = membersFilters[index].teamAgreement && i >= 0 ? teamFile.indexOf('_') : -1
            this.setState({
              commission: membersFilters[index].teamCommissionSplit,
              preSignedUrl: membersFilters[index].teamAgreement ? url.data.preSignedUrl : this.state.preSignedUrl,
              showDetails: index,
              showInputBox: false,
              teamAgreement: membersFilters[index].teamAgreement ? membersFilters[index].teamAgreement : null,
              teamAgreementName:
                membersFilters[index].teamAgreement && value >= 0 ? teamFile.substring(value + 1) : 'No Agreement Found'
            })
          }
          return (
            <ImageContainer key={index}>
              {membersFilters[index].role !== 'TEAM_LEADER' ? (
                <StyledPopup
                  trigger={
                    <Images
                      background={membersFilters[index].profileImage}
                      onClick={showDetailsFunction}
                      border={showDetails === index ? 3 : 0}
                      borderRadius={showDetails === index ? 50 : 50}
                      width={showDetails === index ? 55 : 40}
                      height={showDetails === index ? 55 : 40}
                    />
                  }
                  content={
                    <Delete onClick={() => this.deleteUser(items._id)}>
                      <Icon name="trash alternate outline" />
                    </Delete>
                  }
                  position="bottom center"
                  hoverable={true}
                />
              ) : (
                <Images
                  background={membersFilters[index].profileImage}
                  onClick={showDetailsFunction}
                  border={showDetails === index ? 3 : 0}
                  borderRadius={showDetails === index ? 50 : 50}
                  width={showDetails === index ? 55 : 40}
                  height={showDetails === index ? 55 : 40}
                />
              )}
              {showDetails === index && (
                <Before>
                  <Icon name="play" />
                </Before>
              )}
            </ImageContainer>
          )
        })
      }
      const toggleFunction = () => {
        this.onToggle()
        this.setState({ commission })
      }
      const totalTeamCap = teamDetails[`teamCap`]
        ? Math.round(teamDetails[`teamCap`].residentialCompletedCap) +
          Math.round(teamDetails[`teamCap`].commercialCompletedCap)
        : 0
      const totalTeamCapPer = ((Math.round(residentialPercent) + Math.round(commercialPercent)) * 100) / 200
      return (
        <Container>
          <TeamName>{teamDetails[`name`]}</TeamName>
          <Grid divided={true}>
            <Grid.Row>
              <Grid.Column width={6}>
                <Office>
                  <Text>{Strings.teams.affiliateOffice}</Text>
                  <span>{teamDetails[`office`].branchName}</span>
                </Office>
                <Lead>
                  <Text>{Strings.teams.teamLead}</Text>
                  <span>
                    {teamDetails[`leader`].firstName} {teamDetails[`leader`].lastName}
                  </span>
                </Lead>
              </Grid.Column>
              {capStatus === 'ON' && (
                <Grid.Column width={8}>
                  <ProgressContainer>
                    <div>
                      <svg viewBox="0 0 400 400">
                        <VictoryPie
                          standalone={false}
                          animate={{ duration: 5000 }}
                          width={400}
                          height={400}
                          data={this.getDataResidential(residentialPercent)}
                          innerRadius={150}
                          labelRadius={50}
                          // cornerRadius={50}
                          padding={0}
                          startAngle={-360}
                          endAngle={360}
                          colorScale={[Colors.DarkBlue200, Colors.White900]}
                        />
                        <VictoryPie
                          standalone={false}
                          width={400}
                          height={400}
                          data={this.getDataCommercial(commercialPercent)}
                          innerRadius={150}
                          labelRadius={50}
                          // cornerRadius={50}
                          padding={25}
                          startAngle={-360}
                          endAngle={360}
                          colorScale={[Colors.Black610, Colors.White900]}
                        />
                      </svg>
                      <Completed>
                        <Percentage>{`${Math.round(totalTeamCapPer)}%`}</Percentage>
                        <CompletedText>{Strings.teams.completed}</CompletedText>
                      </Completed>
                    </div>
                  </ProgressContainer>
                  <TeamCAP>
                    <div>
                      <TeamCAPTitle>{Strings.teams.teamCap}</TeamCAPTitle>
                      <ResidentialCAP>
                        <FontAwesomeIcon icon={['fas', 'circle']} />
                        {Strings.teams.residential}
                        {`${Math.round(residentialPercent)}%`}
                      </ResidentialCAP>
                      <CommercialCAP>
                        <FontAwesomeIcon icon={['fas', 'circle']} />
                        {Strings.teams.commercial}
                        {`${Math.round(commercialPercent)}%`}
                      </CommercialCAP>
                    </div>
                  </TeamCAP>
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
          <Title>{Strings.teams.team}</Title>
          {membersFilters.length > 0 && (
            <TeamSection>
              <Propfile>{member}</Propfile>
              <Details>
                <Leader>
                  <MemberName>
                    {membersFilters[showDetails].firstName} {membersFilters[showDetails].lastName}
                  </MemberName>
                  <LeaderName>
                    {Strings.teams.leader} {teamDetails[`leader`].firstName} {teamDetails[`leader`].lastName}
                  </LeaderName>
                </Leader>
                <StyledTeamGrid divided={true}>
                  <Grid.Row>
                    <Grid.Column width={10} style={{ fontSize: '10px' }}>
                      <StyledTable basic="very">
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell>
                              <div>{Strings.teams.commissionEarned}</div>
                              <Since>{moment(membersFilters[showDetails].teamJoining).format('L')}</Since>
                            </Table.Cell>
                            <Table.Cell>:</Table.Cell>
                            <StyledCell>
                              $
                              {membersFilters[showDetails].teamCommissionEarn === null ||
                              membersFilters[showDetails].teamCommissionEarn === undefined
                                ? 0
                                : membersFilters[showDetails].teamCommissionEarn}
                            </StyledCell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>{Strings.teams.teamFee}</Table.Cell>
                            <Table.Cell>:</Table.Cell>
                            <StyledCell>
                              ${membersFilters[showDetails].teamFee === null ? 0 : membersFilters[showDetails].teamFee}
                            </StyledCell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>{Strings.teams.noOf}</Table.Cell>
                            <Table.Cell>:</Table.Cell>
                            <StyledCell>{membersFilters[showDetails].teamTransactions}</StyledCell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>{Strings.teams.commissionSplit}</Table.Cell>
                            <Table.Cell>:</Table.Cell>
                            <Table.Cell>
                              {showInputBox ? (
                                <EditBoardName>
                                  <StyledInput
                                    placeholder="Commission Split"
                                    value={commission}
                                    onChange={this.handleChange}
                                  />
                                  <Icon
                                    style={{ cursor: 'pointer' }}
                                    name="check"
                                    onClick={() => this.changeCommission(membersFilters[showDetails]._id)}
                                  />
                                </EditBoardName>
                              ) : (
                                <StyledCell
                                  onClick={membersFilters[showDetails].role !== 'TEAM_LEADER' ? toggleFunction : null}
                                >
                                  {commission === null ? 0 : commission}
                                  {membersFilters[showDetails].role !== 'TEAM_LEADER' && (
                                    <FontAwesomeIcon icon={['fas', 'pencil-alt']} />
                                  )}
                                </StyledCell>
                              )}
                            </Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </StyledTable>
                    </Grid.Column>
                    {membersFilters[showDetails].role !== 'TEAM_LEADER' && (
                      <StyledColumn
                        width={6}
                        onClick={() => teamAgreementName !== 'No Agreement Found' && this.downloadFile(preSignedUrl)}
                      >
                        <Icon name="file pdf outline" />
                        <Agreement>{Strings.teams.teamAgreement}</Agreement>
                        <FileName>{teamAgreementName}</FileName>
                      </StyledColumn>
                    )}
                  </Grid.Row>
                </StyledTeamGrid>
                <StyledProgress
                  percent={Math.round(Math.round(membersFilters[showDetails].teamCommissionEarn * 100) / totalTeamCap)}
                  progress={true}
                />
                <Contribution>
                  {membersFilters[showDetails].firstName} {membersFilters[showDetails].lastName}{' '}
                  {Strings.teams.CAPContribution}
                </Contribution>
              </Details>
            </TeamSection>
          )}
          {membersFilters.length === 0 && <Text style={{ textAlign: 'center', margin: 148 }}>No Members </Text>}
          <CreatedOn>
            <span>{Strings.teams.createdOn}</span>
            {moment(teamDetails[`createdAt`]).format('L')}
          </CreatedOn>
        </Container>
      )
    }
    return null
  }

  private downloadFile = (fileUrl: any) => {
    const link = document.createElement('a')
    link.setAttribute('href', fileUrl)
    link.setAttribute('download', fileUrl.split('https://onechimp.s3.amazonaws.com/').slice(1))
    link.setAttribute('target', '_blank')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  private handleChange = (e: React.SyntheticEvent<HTMLDivElement>, { value }: any) => {
    this.setState({ commission: value })
  }

  private changeCommission = async (userId: string) => {
    const { commission } = this.state
    this.onToggle()
    client.mutate({
      mutation: UPDATE_USER,
      variables: {
        _id: userId,
        teamCommissionSplit: parseFloat(commission)
      }
    })
    const res = await client.query({
      fetchPolicy: 'network-only',
      query: GET_TEAMS_DETAILS,
      variables: { isActive: true, id: this.props.cardId }
    })
    const { data }: any = res
    this.setState({ teamDetails: data.getTeams[0] })
  }

  private onToggle = () => {
    const { showInputBox } = this.state
    this.setState({ showInputBox: !showInputBox })
  }
}

export default connect(
  null,
  {
    setBoardData: Actions.getBoardData
  }
)(Team)
