import gql from 'graphql-tag'

export const GET_OFFICES_TEAMS = gql`
  query getOfficesTeams(
    $sortOrder: Int
    $orderField: String
    $id: String
    $teamOrderField: String
    $sortTeamOrder: Int
    $filter: TeamSearch
    $singleSkip: Int
  ) {
    getOfficeTeams(
      where: { isActive: true }
      sorting: { field: $orderField, order: $sortOrder }
      _id: $id
      singleSorting: { field: $teamOrderField, order: $sortTeamOrder }
      filter: $filter
      singleSkip: $singleSkip
    ) {
      _id
      branchName
      status
      state
      zipCode
      street
      city
      country
      teamOfficeOrder
      createdAt
      icon
      description
      pagination {
        count
        limit
        pages {
          current
          last
          next
          previous
          total
        }
        skip
        sorting {
          field
          order
        }
      }
      teams {
        _id
        name
        leader {
          _id
          firstName
          lastName
          userName
          phones {
            type
            value
          }
          profileImage
          teamFee
          status
          teamCommissionEarn
          teamCommissionSplit
        }
        members {
          _id
          profileImage
          firstName
          lastName
          userName
          phones {
            type
            value
          }
          teamFee
          teamCommissionEarn
          teamCommissionSplit
          teamAgreement
          status
          teamInvitedDate
        }
        teamCap {
          residentialTargetCap
          commercialTargetCap
          residentialCompletedCap
          commercialCompletedCap
        }
        teamOrder
        isActive
      }
    }
  }
`

export const GET_TEAMS = gql`
  query getTeams($isActive: Boolean, $filter: TeamSearch) {
    getTeams(where: { isActive: $isActive }, filter: $filter) {
      _id
      name
      leader {
        _id
        firstName
        lastName
        userName
        phones {
          type
          value
        }
        profileImage
        teamFee
        status
        teamCommissionEarn
        teamCommissionSplit
      }
      teamCap {
        residentialTargetCap
        commercialTargetCap
        residentialCompletedCap
        commercialCompletedCap
      }
      members {
        _id
        profileImage
        firstName
        lastName
        userName
        phones {
          type
          value
        }
        teamFee
        teamCommissionEarn
        teamCommissionSplit
        teamAgreement
        status
        teamInvitedDate
      }
      teamOrder
      isActive
      createdAt
      office {
        _id
        branchName
      }
      checked @client
    }
  }
`

export const GET_OFFICE_TEAMS = gql`
  query getOfficeTeams(
    $id: ID
    $sortOrder: Int
    $orderField: String
    $filter: TeamSearch
    $singleSkip: Int
    $boardId: String
  ) {
    getOfficeTeams(
      where: { _id: $id, isActive: true }
      sorting: { order: $sortOrder, field: $orderField }
      filter: $filter
      _id: $boardId
      singleSkip: $singleSkip
    ) {
      _id
      branchName
      status
      state
      zipCode
      street
      city
      country
      teamOfficeOrder
      createdAt
      icon
      description
      pagination {
        count
        limit
        pages {
          current
          last
          next
          previous
          total
        }
        skip
        sorting {
          field
          order
        }
      }
      teams {
        _id
        name
        leader {
          _id
          firstName
          lastName
          userName
          phones {
            type
            value
          }
          profileImage
          teamFee
          status
          teamCommissionEarn
          teamCommissionSplit
        }
        members {
          _id
          profileImage
          firstName
          lastName
          userName
          phones {
            type
            value
          }
          teamFee
          teamCommissionEarn
          teamCommissionSplit
          teamAgreement
          status
          teamInvitedDate
        }
        teamCap {
          residentialTargetCap
          commercialTargetCap
          residentialCompletedCap
          commercialCompletedCap
        }
        teamOrder
        isActive
      }
    }
  }
`

export const GET_TEAMS_ON_OFFICE = gql`
  query getTeamsOnOffice($isActive: Boolean, $office: ID, $filter: TeamSearch) {
    getTeams(where: { isActive: $isActive, office: $office }, filter: $filter) {
      _id
      name
      leader {
        _id
        firstName
        lastName
        userName
        phones {
          type
          value
        }
        profileImage
        teamFee
        status
        teamCommissionEarn
        teamCommissionSplit
      }
      members {
        _id
        profileImage
        firstName
        lastName
        userName
        phones {
          type
          value
        }
        teamFee
        teamCommissionEarn
        teamCommissionSplit
        teamAgreement
        status
        teamInvitedDate
      }
      teamCap {
        residentialTargetCap
        commercialTargetCap
        residentialCompletedCap
        commercialCompletedCap
      }
      teamOrder
      isActive
      createdAt
      office {
        _id
        branchName
      }
    }
  }
`

export const GET_TEAMS_DETAILS = gql`
  query getTeamDetails($isActive: Boolean, $id: ID) {
    getTeams(where: { isActive: $isActive, _id: $id }) {
      _id
      name
      leader {
        _id
        firstName
        lastName
        userName
        phones {
          type
          value
        }
        profileImage
        teamFee
        status
        teamJoining
        teamTransactions
        teamCommissionEarn
        teamCommissionSplit
        role
      }
      members {
        _id
        profileImage
        firstName
        lastName
        userName
        phones {
          type
          value
        }
        teamFee
        teamJoining
        teamTransactions
        teamCommissionEarn
        teamCommissionSplit
        teamAgreement
        status
        teamInvitedDate
        role
      }
      teamCap {
        residentialTargetCap
        commercialTargetCap
        residentialCompletedCap
        commercialCompletedCap
      }
      teamOrder
      isActive
      createdAt
      office {
        _id
        branchName
      }
    }
  }
`
