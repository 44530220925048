export const handleValidation = (data: object) => {
  const fields = data
  const errors = {}
  let formIsValid = true

  if (!fields[`firstName`]) {
    formIsValid = false
    errors[`firstName`] = 'Can Not be empty'
  }

  if (!fields[`lastName`]) {
    formIsValid = false
    errors[`lastName`] = 'Can Not be empty'
  }

  if (
    !fields[`userName`] ||
    !fields[`userName`].match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  ) {
    formIsValid = false
    errors[`userName`] = 'Can Not be empty'
  }

  if (!fields[`phones`]) {
    formIsValid = false
    errors[`phones`] = 'Can Not be empty'
  }

  if (!fields[`teamFee`]) {
    formIsValid = false
    errors[`teamFee`] = 'Can Not be empty'
  }

  if (!fields[`teamCommissionSplit`] || Number(fields[`teamCommissionSplit`]) > 100) {
    formIsValid = false
    errors[`teamCommissionSplit`] = 'Can Not be empty'
  }

  return { errors, formIsValid }
}
