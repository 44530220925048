// Import Packages
import { debounce, escapeRegExp, filter } from 'lodash'
import * as React from 'react'
import InputMask from 'react-input-mask'
import { connect } from 'react-redux'
import { Button, Form, Input } from 'semantic-ui-react'

// Import Components
import Toast from 'shared/Toast'
import client from 'queries/apollo'
import { handleValidation } from './Validation'

import { getReassignUsers } from '../../../Leads/Dashboard/LeadQueries'
import { updateProfile } from '../../../UserProfile/UserProfileMutations'

// Import Graphql Mutations
import { INVITE_MEMBER } from 'queries/graphql/Teams/Mutation'
import { GET_OFFICES_TEAMS } from 'queries/graphql/Teams/Queries'
import { FormErrorType, FormType } from './Types'

// Import Utils
import { Strings } from 'utils'

// Import Colors
import Colors from 'design/Colors'

import * as Actions from 'store/Teams/Actions'
import { TeamDetails, TeamShareWith, UserOptions } from 'store/Teams/Types'
import { formatKanbanData } from '../../Utils/FormattingData'

// Import Styled Components
import { StyledForm, StyledInput, StyledLabel, StyledSearch } from './Styled'

// Font Awesome Icons
import { faCloudUploadAlt } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faCloudUploadAlt])

interface StoreProps {
  setBoardData: (data: TeamDetails[]) => void
}

interface OwnProps {
  cardId: string
  editInvite: FormType
  closeModal: () => void
  editedInvite: () => void
}

type Props = StoreProps & OwnProps

interface State {
  form: FormType
  fileName: any
  isLoading: boolean
  shareWithObj: TeamShareWith
  value: string
  firstname: string
  results: TeamShareWith[]
  userOptions: UserOptions[]
  loader: boolean
}

class InviteMember extends React.Component<Props, State> {
  public textInput: any = React.createRef()

  public state = {
    fileName: {},
    firstname: '',
    form: {
      errors: {} as FormErrorType
    } as FormType,
    isLoading: false,
    loader: false,
    results: [],
    shareWithObj: {} as TeamShareWith,
    userOptions: [],
    value: ''
  }

  public async componentDidMount() {
    const { editInvite } = this.props
    let { form } = this.state
    if (Object.keys(editInvite).length > 0) {
      form = editInvite
      form.errors = {} as FormErrorType
      form[`phones`] = editInvite[`phones`][0].value
      form[`teamFee`] = form[`teamFee`].toString()
      form[`teamCommissionSplit`] = form[`teamCommissionSplit`].toString()
      this.setState({ form })
    } else {
      this.setState({ form })
    }

    const membersList: any = []
    const usersOpt: any = []
    let data: any = {}
    data = await client.query({
      fetchPolicy: 'network-only',
      query: GET_OFFICES_TEAMS,
      variables: { sortOrder: 1, orderField: 'teamOfficeOrder' }
    })
    data.data.getOfficeTeams.forEach((user: any) => {
      user.teams.forEach((list: any) => {
        if (list._id === this.props.cardId) {
          membersList.push(list.members)
        }
      })
    })

    const users = await getReassignUsers()
    const usersList: any = users.filter((user: any) => {
      let found = false
      membersList[0].forEach((list: any) => {
        if (list._id === user._id) {
          found = true
        }
      })
      return !found
    })

    const usersFilters = usersList.filter((user: any) => {
      return user.role === 'AGENT' || user.role === 'GUEST' || user.hasTeam === false
    })

    usersFilters.forEach((user: any) => {
      const obj: any = {
        _id: user._id,
        description: user.emails && user.emails[0] ? user.emails[0].value : null,
        phones: user.phones && user.phones[0] ? user.phones[0].value : null,
        title: `${user.firstName} ${user.lastName}`
      }
      usersOpt.push(obj)
    })
    this.setState({ userOptions: usersOpt })
  }

  public render() {
    const { editInvite } = this.props
    const {
      form,
      form: { errors },
      isLoading,
      results,
      loader,
      value
    } = this.state
    let fileName = ''
    if (this.state.fileName && this.state.fileName[0]) {
      fileName = this.state.fileName[0].name
    } else if (form[`teamAgreement`]) {
      const index = form[`teamAgreement`].lastIndexOf('_')
      fileName = form[`teamAgreement`].substring(index + 1)
    }
    return (
      <StyledForm>
        {Object.keys(editInvite).length > 0 ? (
          <Form.Field
            control={Input}
            label="First Name"
            name="firstName"
            placeholder="First Name"
            onChange={this.handleChange}
            value={form.firstName}
            error={!!errors.firstName}
          />
        ) : (
          <Form.Field error={!!errors.firstName}>
            <StyledLabel>First Name</StyledLabel>
            <StyledSearch
              loading={isLoading}
              onResultSelect={this.handleResultSelect}
              onSearchChange={debounce(this.handleSearchChange, 500, { leading: true })}
              onChange={this.handleChange}
              name="firstName"
              placeholder="First Name"
              results={results}
              value={value}
              {...this.props}
            />
          </Form.Field>
        )}
        <Form.Field
          control={Input}
          label="Last Name"
          name="lastName"
          placeholder="Last Name"
          onChange={this.handleChange}
          value={form.lastName}
          error={!!errors.lastName}
        />
        <Form.Field
          control={Input}
          label="Email"
          name="userName"
          placeholder="Email"
          onChange={(values: any) =>
            Object.keys(editInvite).length > 0
              ? null
              : this.handleChange(values, { name: values.target.name, value: values.target.value })
          }
          value={form.userName}
          error={!!errors.userName}
          disabled={Object.keys(editInvite).length > 0 ? true : false}
        />
        <Form.Field control={Input} label="Phone" className="amount" error={!!errors.phones}>
          <InputMask
            mask="(999) 999-9999"
            name="phones"
            placeholder="(xxx) xxx-xxxx"
            onChange={this.onChangePhone}
            value={form.phones}
          />
        </Form.Field>
        <Form.Field
          type="number"
          control={Input}
          label="Fee"
          name="teamFee"
          placeholder="Fee"
          onChange={this.handleChange}
          value={form.teamFee}
          error={!!errors.teamFee}
        />
        <Form.Field
          type="number"
          control={Input}
          label="Commission Split"
          icon="percent"
          name="teamCommissionSplit"
          placeholder="Commission Split"
          onChange={this.handleChange}
          value={form.teamCommissionSplit}
          error={!!errors.teamCommissionSplit}
        />
        <div
          style={{
            color: Colors.Black500,
            fontSize: '.92857143em',
            fontWeight: 'bold',
            marginBottom: '5px'
          }}
        >
          {'Team Agreement '}
          <span
            style={{
              color: Colors.Black550,
              fontSize: '10px',
              fontStyle: 'italic',
              marginLeft: '5px'
            }}
          >
            {Strings.leads.optional}
          </span>
        </div>
        <StyledInput
          action={{ icon: <FontAwesomeIcon icon={['fas', 'cloud-upload-alt']} />, onClick: this.handleFile }}
          name="fileName"
          placeholder="Upload File"
          value={fileName}
          disabled={Object.keys(editInvite).length > 0 ? true : false}
          onChange={this.handleChange}
        />
        <input
          type="file"
          ref={(node: any) => {
            this.textInput = node
          }}
          accept="application/pdf"
          id="hidden-new-file"
          style={{ display: 'none' }}
          name="fileName"
          disabled={Object.keys(editInvite).length > 0 ? true : false}
          onChange={(e: any) => {
            this.setState({ fileName: e.target.files })
          }}
        />
        {Object.keys(editInvite).length > 0 ? (
          loader ? (
            <Form.Field className="save-button" control={Button} loading={true} content="LOADING" />
          ) : (
            <Form.Field
              className="save-button"
              control={Button}
              content={'Edit Invitation'}
              onClick={this.editInvite}
            />
          )
        ) : loader ? (
          <Form.Field className="save-button" control={Button} loading={true} content="LOADING" />
        ) : (
          <Form.Field className="save-button" control={Button} content={'+ Invite'} onClick={this.saveAddInvite} />
        )}
      </StyledForm>
    )
  }

  private handleChange = (e: React.SyntheticEvent<HTMLDivElement>, { name, value }: any) => {
    const { form } = this.state
    form[name] = value
    this.setState({ form })
  }

  private onChangePhone = (event: any) => {
    const { form } = this.state
    form[`phones`] = event.target.value
    this.setState({ form })
  }

  private handleResultSelect = (e: React.SyntheticEvent<EventTarget>, { result }: any) => {
    const { form } = this.state
    const res = result.title.split(' ')
    form[`userId`] = result._id
    form[`firstName`] = res[0]
    form[`lastName`] = res[1]
    form[`userName`] = result.description
    form[`phones`] = result.phones
    this.setState({ value: res[0], form })
  }

  private handleSearchChange = (e: React.SyntheticEvent<EventTarget>, { value, name }: any) => {
    const { form } = this.state
    form[`firstName`] = value
    this.setState({ isLoading: true, value, form })
    setTimeout(() => {
      if (this.state.value.length < 1) {
        return this.resetComponent()
      }
      const re = new RegExp(escapeRegExp(this.state.value), 'i')
      const isMatch = (result: any) => re.test(result.title)
      const results = filter(this.state.userOptions, isMatch)
      if (results.length > 0) {
        this.setState({
          isLoading: false,
          results
        })
      } else {
        form[name] = value
        this.setState({ form })
      }
    }, 300)
  }

  private resetComponent = () => this.setState({ isLoading: false, results: [], value: '' })

  private handleFile = () => {
    this.textInput.click()
  }

  private editInvite = async () => {
    const { closeModal, editInvite, editedInvite } = this.props
    const { form } = this.state

    const validation = handleValidation(form)
    if (!validation.formIsValid) {
      form.errors = validation.errors
      this.setState({ form })
      return
    }

    this.setState({ loader: true })
    form[`teamFee`] = parseInt(form[`teamFee`])
    form[`teamCommissionSplit`] = parseFloat(form[`teamCommissionSplit`])
    form[`phones`] = {
      type: 'Mobile',
      value: form[`phones`]
    }
    if (Object.keys(editInvite).length > 0) {
      const basic = {
        _id: form[`_id`]
      }
      try {
        await updateProfile(form, basic)
        editedInvite()
        this.setState({ loader: false })
        Toast({ message: 'User updated successfully', type: 'success' })
      } catch (error) {
        Toast({ message: error.message, type: 'error' })
      }
    }
    closeModal()
  }

  private saveAddInvite = async () => {
    const { cardId, closeModal, setBoardData } = this.props
    const { form, fileName } = this.state

    const validation = handleValidation(form)
    if (!validation.formIsValid) {
      form.errors = validation.errors
      this.setState({ form })
      return
    }

    this.setState({ loader: true })
    form[`teamFee`] = parseInt(form[`teamFee`])
    form[`teamCommissionSplit`] = parseFloat(form[`teamCommissionSplit`])
    form[`phones`] = {
      type: 'Mobile',
      value: form[`phones`]
    }
    try {
      await client.mutate({
        mutation: INVITE_MEMBER,
        variables: {
          file: fileName[0],
          firstName: form[`firstName`],
          lastName: form[`lastName`],
          phones: form[`phones`],
          teamCommissionSplit: form[`teamCommissionSplit`],
          teamFee: form[`teamFee`],
          teamId: cardId,
          userId: form[`userId`],
          userName: form[`userName`]
        }
      })
      this.setState({ loader: false })
      Toast({ message: 'Team Invitation Sent', type: 'success' })
      closeModal()
    } catch (error) {
      form[`phones`] = form[`phones`].value
      Toast({ message: 'Email already exists', type: 'error' })
      this.setState({ loader: false })
    }
    const data = await client.query({
      fetchPolicy: 'network-only',
      query: GET_OFFICES_TEAMS,
      variables: { sortOrder: 1, orderField: 'teamOfficeOrder' }
    })
    const kanbanData = formatKanbanData(data.data.getOfficeTeams, false)
    setBoardData(kanbanData)
  }
}

export default connect(
  null,
  {
    setBoardData: Actions.getBoardData
  }
)(InviteMember)
