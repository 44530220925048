import { merge } from 'lodash'
import * as React from 'react'
import { Button, Form, Image, Input, Select } from 'semantic-ui-react'

import SubForm from './SubForm'

import LoadingIndicator from 'shared/LoadingIndicator'
import Modal from 'shared/Modal'
import { formToast, serverToast } from 'shared/Toast/Toast'

import { handleValidationForEscrow, handleValidationForExternal, handleValidationForInternal, handleValidationForThirdParty } from './Validation'
import { Strings } from 'utils'

import { CommissionItemValueType, CommissionType, TransactionType, } from 'app/Transactions/Details/Types'
import { AddressType } from 'shared/AddressInput/Types'
import { PaymentNumericEnum } from 'store/Transactions/Types'
import { FormErrorType, FormType, OptionType, SearchOptionType, ModeEnum, ReferralTypeEnum, PaymentMethodEnum } from './Types'

import { getReassignUsers } from 'app/Leads/Dashboard/LeadQueries'
import { getUserOffices } from 'app/Transactions/Dashboard/TransactionQueries'
import { updateProfile } from 'app/UserProfile/UserProfileMutations'

import { AddContactLeftPanel, AddForm, Container, StyledForm } from './Styled'

interface Props {
  commission: CommissionType
  inputData: any
  onAdd: (newData: FormType) => void
  onClose: () => void
  onUpdate: (id: string, newData: FormType) => void
  transaction: TransactionType
}

interface State {
  form: FormType
  loading: string
  mode: ModeEnum
  officeOptions: OptionType[]
  transition: boolean
  userOptions: SearchOptionType[]
}

const options = [
  { key: '$', text: '$', value: PaymentNumericEnum.Fixed },
  { key: '%', text: '%', value: PaymentNumericEnum.Percent }
]

let selectReferralTypeOptions = [
  { key: ReferralTypeEnum.Internal, text: 'Internal', value: ReferralTypeEnum.Internal },
  { key: ReferralTypeEnum.External, text: 'External', value: ReferralTypeEnum.External },
  { key: ReferralTypeEnum.ThirdParty, text: 'Client', value: ReferralTypeEnum.ThirdParty },
  { key: ReferralTypeEnum.Escrow, text: 'Pay via Escrow', value: ReferralTypeEnum.Escrow, disabled: false }, // disable the escrow option when it's already set
]

class AddReferralFeeModal extends React.Component<Props, State> {
  public state = {
    form: {
      address: {} as AddressType,
      errors: {} as FormErrorType,
      feeObj: {
        type: PaymentNumericEnum.Fixed,
      } as CommissionItemValueType,
    } as FormType,
    loading: '',
    mode: ModeEnum.Add,
    officeOptions: [] as OptionType[],
    transition: true,
    userOptions: [] as SearchOptionType[],
  }

  public async componentDidMount() {
    const { inputData } = this.props
    let { form, mode } = this.state
    // disable the escrow option when it's already set
    selectReferralTypeOptions = selectReferralTypeOptions.map(option => {
      option.disabled = option.key === ReferralTypeEnum.Escrow
                        &&
                        this.props.commission.referrals?.find((referral: any) => referral.type === ReferralTypeEnum.Escrow)
      return option
    })

    this.setState({ loading: 'Gathering data...' })

    const offices: any = await getUserOffices() || []
    const officeOptions: OptionType[] = offices.map((element: any) => {
      return {
        key: element._id,
        text: element.branchName,
        value: element._id
      }
    })

    const users: any = await getReassignUsers() || []
    const userOptions = users.map((element: any) => {
      return {
        _id: element._id,
        description: element.emails && element.emails[0] ? element.emails[0]!!.value : '',
        title: `${element.firstName} ${element.lastName}`
      }
    })

    if (inputData && Object.keys(inputData).length > 0) {
      mode = ModeEnum.Edit
      const user = (inputData.user && inputData.user._id) || ''
      let address:AddressType = { ...inputData.address }
      if (inputData.address) {
        address.streetAddress = `${inputData.address.streetNumber || ''} ${inputData.address.streetName || ''}`
      }
      form = {
        ...form,
        ...inputData,
        address,
        errors: {} as FormErrorType,
        office: (inputData.office && inputData.office._id) || officeOptions[0].value || '',
        payment: inputData.payment._id || '',
        user,
      }

      if (user) {
        form.name = (userOptions.find((item:any) => user === item._id) || {} as SearchOptionType[]).title || ''
      }
    }

    this.setState({
      form,
      loading: '',
      mode,
      officeOptions,
      userOptions,
    })
  }

  public render() {
    const {
      commission,
      transaction,
    } = this.props

    const {
      form,
      form: { errors },
      loading,
      mode,
      officeOptions,
      transition,
      userOptions,
    } = this.state

    return (
      <Modal
        content={
          <Container>
            {loading && <LoadingIndicator message={loading} />}
            <AddContactLeftPanel>
              <Image src={Strings.transactionFeeSettings.src} size='small' />
            </AddContactLeftPanel>
            <AddForm>
              <StyledForm size={'mini'}>
                <Form.Field
                  control={Select}
                  label='Select Referral Type'
                  name='type'
                  value={form.type}
                  error={!!errors.type}
                  options={selectReferralTypeOptions}
                  placeholder='Referral Type'
                  className='external'
                  onChange={this.handleChange}
                  disabled={mode === ModeEnum.Edit}
                />
                <Form.Group widths='equal'>
                  <Form.Field
                    control={Input}
                    label='Amount'
                    type='number'
                    name='fee'
                    value={form.feeObj.fee}
                    className='amount'
                    onChange={this.handleFeeChange}
                    error={!!errors.amount}
                  />
                  <Form.Field
                    control={Select}
                    options={options}
                    placeholder='$'
                    label='currency'
                    name='type'
                    value={form.feeObj.type}
                    className='dollar'
                    onChange={this.handleFeeChange}
                    error={!!errors.currency}
                  />
                </Form.Group>
                {form.type === ReferralTypeEnum.External && (
                  <SubForm.External
                    form={form}
                    onChange={this.handleChange}
                  />
                )}
                {form.type === ReferralTypeEnum.Internal && (
                  <SubForm.Internal
                    form={form}
                    onChange={this.handleChange}
                    userOptions={userOptions}
                    officeOptions={officeOptions}
                  />
                )}
                {form.type === ReferralTypeEnum.Escrow && (
                  <SubForm.Escrow
                    commission={commission}
                    form={form}
                    onUpdate={this.handleFormUpdate}
                    transaction={transaction}
                  />
                )}
                {form.type === ReferralTypeEnum.ThirdParty && (
                  <SubForm.ThirdParty
                    form={form}
                    onChange={this.handleChange}
                  />
                )}
                <Button
                  content={mode === ModeEnum.Edit ? 'UPDATE' : 'SAVE'}
                  onClick={this.handleSubmit}
                />
              </StyledForm>
            </AddForm>
          </Container>
        }
        className={transition ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeSelf}
        width={700}
      />
    )
  }

  private handleFeeChange = async (e: React.SyntheticEvent<HTMLDivElement>, { name, value }: any) => {
    const { form } = this.state
    form.feeObj[name] = value
    this.setState({ form })
  }

  private handleChange = async (e: any, { name, value }: any) => {
    const { form } = this.state
    form[name] = value
    this.setState({ form })
  }

  private handleFormUpdate = (form: FormType) => {
    this.setState({ form })
  }

  private handleSubmit = () => {
    const { onAdd, onUpdate, transaction } = this.props
    const { form, mode, userOptions } = this.state

    let data:any = merge({}, form)
    delete data.errors

    let validation: any = {}

    switch (form.type) {
      case ReferralTypeEnum.External:
        validation = handleValidationForExternal(form)
        delete data.user
        break
      case ReferralTypeEnum.Internal:
        validation = handleValidationForInternal(form, userOptions)
        delete data.address
        break
      case ReferralTypeEnum.ThirdParty:
        validation = handleValidationForThirdParty(form)
        delete data.address
        delete data.user
        break
      case ReferralTypeEnum.Escrow:
        validation = handleValidationForEscrow(form)
        data.accountNumber = form.accountNumber
        data.routingNumber = form.routingNumber
        data.name = 'Pay via Escrow'

        const profileUpdate:any = {
          _id: transaction.owner._id,
          taxID: form.brokerageTaxID,
        }

        if (form.paymentMethod === PaymentMethodEnum.Ach) {
          delete data.address
          profileUpdate.accountNumber = form.accountNumber
          profileUpdate.bankName = form.bankName
          profileUpdate.routingNumber = form.routingNumber
        }

        updateProfile(profileUpdate, { _id: transaction.owner._id })
        delete data.user
        break
      default:
        validation.formIsValid = false
        validation.errors = { general: 'Referral type was not specified.' }
        break
    }

    if (!validation.formIsValid) {
      form.errors = validation.errors
      formToast(validation.errors)
      this.setState({ form })
      return
    }

    try {
      if (mode === ModeEnum.Edit) {
        onUpdate(form._id, data)
      } else {
        onAdd(data)
      }
      this.closeSelf()
    } catch (e) {
      serverToast(e)
    }
  }

  private closeSelf = () => {
    const { onClose } = this.props
    this.setState({ transition: false })
    window.setTimeout(() => {
      onClose()
    }, 300)
  }
}

export default AddReferralFeeModal
