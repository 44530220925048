import * as QueryString from 'query-string'
import { Component } from 'react'
import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/UserProfile/Actions'

import Scrollable from 'shared/Scrollable'
import { getLoggedInUser } from 'utils'

import LoadingIndicator from 'shared/LoadingIndicator'
import { AddAccTransaction, ReferralPopup, SalesGoalsModal } from './Modals'
import ProfileDetail from './ProfileDetail'
import UserInfo from './UserInfo'

import { getUserById } from 'shared/Users/Queries'
import { createDebitCredit, deleteCreditDebit, updateCreditDebit } from './UserProfileMutations'
import { getAllFolderFiles, userProfileCounter } from './UserProfileQueries'

import { Container, FlexWrapper } from './Styled'

import { IndicatorTypeEnum } from 'shared/LoadingIndicator/Types'
import {
  ProfilePassFilter,
  UserCreditDebit,
  UserProfileCounters,
  UserProfileType,
  UserType
} from 'store/UserProfile/Types'
import { RouteParams } from './Types'

export let editUserCreditDebit: any
export let deleteUserCreditDebits: any
export let currentUser: any
export let UpdateProgress: any

interface StoreProps {
  setNewUserCreditDebit: (data: UserCreditDebit) => void
  setDeleteUserCreditDebit: (data: string) => void
  setUserCreditDebit: (data: UserCreditDebit[]) => void
  setUpdateUserCreditDebit: (data: UserCreditDebit) => void
  setProfilePercent: (data: number) => void
  setShowUserPassProfileFilterData: (filter: ProfilePassFilter) => void
  setShowStorageUsed: (data: number) => void
  creditDebit: UserCreditDebit[]
  profileProgress: number
  storageUsed: number
}

type Props = StoreProps & RouteComponentProps<RouteParams>

interface State {
  id: string
  showAccTransaction: boolean
  showChangePassword: boolean
  showPaymentHistory: boolean
  showPaymentInfo: boolean
  showReferralPopup: boolean
  userProfile: UserProfileType
  showEditButton: string
  editCreditDebitObj: UserCreditDebit
  edit: boolean
  loader: boolean
  activeIndex: number
  showSalesGoals: boolean
  toggleTab: boolean
  userProfileCounters: UserProfileCounters
}

export let ShowCreditTab: any

class UserProfileComponent extends Component<Props, State> {
  public state = {
    activeIndex: 0,
    edit: false,
    editCreditDebitObj: {} as UserCreditDebit,
    id: '',
    loader: true,
    showAccTransaction: false,
    showChangePassword: false,
    showEditButton: '',
    showPaymentHistory: false,
    showPaymentInfo: false,
    showReferralPopup: false,
    showSalesGoals: false,
    toggleTab: false,
    userProfile: {} as UserProfileType,
    userProfileCounters: {} as UserProfileCounters
  }

  public async componentDidMount() {
    this.getProfile()
    this.editCreditDebit()
    this.deleteCreditDebit()
    this.assignShowCreditTab()
    this.assignUpdateProgress()
  }

  public async getProfile() {
    const { setProfilePercent, match, setShowUserPassProfileFilterData, setShowStorageUsed, location } = this.props
    let showEditButton: string = ''
    const loggedUser: UserType = await getLoggedInUser({ fromCache: true })
    currentUser = loggedUser && loggedUser
    const userId = match.params.id
    const response = await userProfileCounter(userId)
    const storageUsed = await getAllFolderFiles(loggedUser._id)
    setShowStorageUsed(storageUsed.totalSize)
    this.setState({ id: userId, userProfileCounters: response })
    const user = await getUserById(userId)
    setProfilePercent(user)
    if (loggedUser.role === 'ADMIN' || loggedUser.role === 'MANAGER') {
      showEditButton = loggedUser.role
    } else if (loggedUser._id === userId) {
      showEditButton = 'USER'
    }
    if (user) {
      this.setState({ loader: false, userProfile: user, showEditButton })
      if (location && location.search) {
        const query = QueryString.parse(location.search, { parseNumbers: true })
        if (query.tab !== 'Documents' && query.tab !== 'Links') {
          this.setState({ showReferralPopup: true })
        }
      } else {
        setShowUserPassProfileFilterData({} as ProfilePassFilter)
      }
    }
  }

  public componentDidUpdate() {
    const { match } = this.props
    const { id } = this.state
    const userID = match.params.id
    if (userID !== id) {
      this.getProfile()
    }
  }

  public openPaymentInfo = () => {
    this.setState({ showPaymentInfo: true })
  }

  public closePaymentInfo = () => {
    this.setState({ showPaymentInfo: false })
  }

  public openPaymentHistory = () => {
    this.setState({ showPaymentHistory: true })
  }

  public closePaymentHistory = () => {
    this.setState({ showPaymentHistory: false })
  }

  public openChangePassword = () => {
    this.setState({ showChangePassword: true })
  }

  public closeChangePassword = () => {
    this.setState({ showChangePassword: false })
  }

  public openReferralPopup = () => {
    this.setState({ showReferralPopup: true })
  }

  public closeReferralPopup = () => {
    this.setState({ showReferralPopup: false })
  }

  public openAccTransactionPopup = () => {
    this.setState({ showAccTransaction: true })
  }

  public openSalesGoalsPopup = () => {
    this.setState({ showSalesGoals: true })
  }

  public closeSalesGoalsPopup = () => {
    this.setState({ showSalesGoals: false })
  }

  public closeAccTransactionPopup = () => {
    this.setState({ showAccTransaction: false, edit: false, editCreditDebitObj: {} as UserCreditDebit })
  }

  public render() {
    const {
      creditDebit,
      setDeleteUserCreditDebit,
      setUserCreditDebit,
      profileProgress,
      setUpdateUserCreditDebit,
      storageUsed
    } = this.props
    const {
      activeIndex,
      edit,
      editCreditDebitObj,
      id,
      loader,
      showAccTransaction,
      showEditButton,
      showReferralPopup,
      showSalesGoals,
      toggleTab,
      userProfile,
      userProfileCounters
    } = this.state
    return (
      <Scrollable>
        {showReferralPopup && <ReferralPopup handleClose={this.closeReferralPopup} userProfile={userProfile} />}
        {showSalesGoals && <SalesGoalsModal handleClose={this.closeSalesGoalsPopup} />}
        {showAccTransaction && (
          <AddAccTransaction
            handleClose={this.closeAccTransactionPopup}
            addCreditDebit={this.addCreditDebit}
            editCreditDebitObj={editCreditDebitObj}
            edit={edit}
          />
        )}
        <Container>
          {toggleTab && <div className={'block'} />}
          {loader && <LoadingIndicator type={IndicatorTypeEnum.Spinner} />}
          {Object.keys(userProfile).length > 0 && (
            <FlexWrapper stretched={true} columns="equal">
              <UserInfo
                profileProgress={profileProgress}
                userID={id}
                showToggleTab={this.showToggleTab}
                openReferralPopup={this.openReferralPopup}
                userProfile={userProfile}
                showEditButton={showEditButton}
                toggleTab={toggleTab}
                storageUsed={storageUsed}
              />
              <ProfileDetail
                userID={id}
                activeIndex={activeIndex}
                openSalesGoalsPopup={this.openSalesGoalsPopup}
                openPaymentInfo={this.openPaymentInfo}
                openPaymentHistory={this.openPaymentHistory}
                openChangePassword={this.openChangePassword}
                openAccTransaction={this.openAccTransactionPopup}
                userProfile={userProfile}
                showEditButton={showEditButton}
                getUserCreditDebit={setUserCreditDebit}
                deleteUserCreditDebit={setDeleteUserCreditDebit}
                updateUserCreditDebit={setUpdateUserCreditDebit}
                creditDebit={creditDebit}
                openReferralPopup={this.openReferralPopup}
                userProfileCounter={userProfileCounters}
              />
            </FlexWrapper>
          )}
        </Container>
      </Scrollable>
    )
  }

  private showToggleTab = (data: boolean) => {
    this.setState({ toggleTab: data })
  }

  private addCreditDebit = async (data: UserCreditDebit) => {
    const { id, edit } = this.state
    const { setNewUserCreditDebit, setUpdateUserCreditDebit } = this.props
    if (edit) {
      const response = await updateCreditDebit(data)
      setUpdateUserCreditDebit(response)
    } else {
      const response = await createDebitCredit(data, id)
      setNewUserCreditDebit(response)
    }
  }

  private editCreditDebit = () => {
    editUserCreditDebit = async (data: UserCreditDebit) => {
      this.setState({ showAccTransaction: true, editCreditDebitObj: data, edit: true })
    }
  }

  private deleteCreditDebit = () => {
    deleteUserCreditDebits = async (id: string) => {
      const { setDeleteUserCreditDebit } = this.props
      await deleteCreditDebit(id)
      setDeleteUserCreditDebit(id)
    }
  }

  private assignShowCreditTab = () => {
    ShowCreditTab = () => {
      this.setState({ activeIndex: 2 })
    }
  }

  private assignUpdateProgress = () => {
    UpdateProgress = () => {
      this.getProfile()
    }
  }
}

const mapStateToProps = (state: AppState) => ({
  creditDebit: state.userProfile.creditDebit,
  profileProgress: state.userProfile.profileProgress,
  storageUsed: state.userProfile.storageUsed
})

export default withRouter(
  connect(
    mapStateToProps,
    {
      setDeleteUserCreditDebit: Actions.deleteUserCreditDebit,
      setNewUserCreditDebit: Actions.addUserCreditDebit,
      setProfilePercent: Actions.profilePercent,
      setShowStorageUsed: Actions.showStorageUsed,
      setShowUserPassProfileFilterData: Actions.showUserPassProfileFilterData,
      setUpdateUserCreditDebit: Actions.updateUserCreditDebit,
      setUserCreditDebit: Actions.getUserCreditDebit
    }
  )(UserProfileComponent)
)
