import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popup } from 'semantic-ui-react'
import styled, { css } from 'styled-components'

import Colors from 'design/Colors'
import {
  icon,
  styledToolTip,
} from 'design/Styled'

interface Props {
  size?: number
  type?: 'primary' | 'danger' | 'light' | 'disable'
}

export const ToolTip = styled(Popup)`
  ${styledToolTip};
`

const getBackground = ({ type }:Props) => {
  switch (type) {
    case 'danger':
      return css`
        color: ${Colors.TextLight};
        background: ${Colors.ButtonDanger};
      `

    case 'light':
      return css`
        color: ${Colors.TextDark};
        background: ${Colors.Container};
      `

    case 'disable':
      return css`
        color: ${Colors.TextLight};
        background: ${Colors.ButtonDisable};
        &:hover {
          transform: scale3d(0.9, 0.9, 0.9);
        }
        cursor: not-allowed;
      `

    case 'primary':
    default:
      return css`
        color: ${Colors.TextLight};
        background: ${Colors.ButtonPrimary};
      `
  }
}

export const Button = styled.span`
  ${icon};
  padding: 0;
  border-radius: 50%;
  width: ${(props:Props) => props.size || 22}px;
  height: ${(props:Props) => props.size || 22}px;
  min-width: ${(props:Props) => props.size || 22}px;
  min-height: ${(props:Props) => props.size || 22}px;
  justify-content: center;
  align-items: center;

  ${getBackground};
`

export const Icon = styled(FontAwesomeIcon)`
  &.svg-inline--fa {
    display: block;
    line-height: 0;
    vertical-align: 0;
    padding: 0;
    width: 1em;
    height: 1em;
  }
`
