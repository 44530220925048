import * as React from 'react'
import NumberFormat from 'react-number-format'

import AddressInput from 'shared/AddressInput'
import Scrollable from 'shared/Scrollable'
import StateDropdown from 'shared/StateDropdown'
import { serverToast } from 'shared/Toast/Toast'
import Transition from 'shared/Transition'

import { PaymentNumericEnum } from 'store/Transactions/Types'


import {
  getIdxData,
  getOffices,
  getTransactionCategories,
  getTransactionSubcategories
} from '../Queries'

import {
  Col,
  Container,
  Header,
  Image,
  Label,
  Row,
  Section,
  StyledDropdown,
  StyledField,
  StyledInput,
  Submit
} from './Styled'

import { AddressModeEnum, AddressType } from 'shared/AddressInput/Types'
import {
  ActionEnum,
  CategoryType,
  FormType,
  GRAPHIC,
  ModeEnum,
  OfficeType,
  OptionType,
  ROLE_OPTIONS,
  SubcategoryType
} from './Types'
import { CommissionItemValueType } from 'app/Transactions/Details/Types'

interface Props {
  disableOfficeSelection: boolean
  editing: boolean
  form: FormType
  mode: ModeEnum
  onAction: (action: ActionEnum, data: any) => void
  token?: string
}

interface State {
  categories: OptionType[]
  isReady: boolean
  offices: OptionType[]
  subcategories: OptionType[]
}

// percentageDropdown
const options = [
  { key: '$', text: '$', value: PaymentNumericEnum.Fixed },
  { key: '%', text: '%', value: PaymentNumericEnum.Percent }
]

class TransactionForm extends React.Component<Props, State> {
  public state = {
    categories: [] as OptionType[],
    isReady: false,
    offices: [] as OptionType[],
    subcategories: [] as OptionType[]
  }

  public componentDidMount = async () => {
    const { form, onAction, token } = this.props

    let offices = [] as OptionType[]
    const officeData = await getOffices(token)
    if (officeData) {
      offices = officeData.map((item: OfficeType) => ({
        key: item._id,
        text: item.branchName,
        value: item._id
      }))
      if (offices.length === 1) {
        onAction(ActionEnum.UpdateOne, {
          name: 'office',
          value: offices[0].value
        })
      }
    }

    let categories = [] as OptionType[]
    const transactionCategories = await getTransactionCategories(token)
    if (transactionCategories) {
      categories = transactionCategories.map((item: CategoryType) => ({
        key: item._id,
        text: item.name,
        value: item._id
      }))
    }

    this.setState({
      categories,
      isReady: true,
      offices
    })

    if (form.type) {
      this.getSubcategories(form.type)
    }

    onAction(ActionEnum.UpdateOne, {
      name: 'feeObj', value: {type: PaymentNumericEnum.Fixed}
    })
  }

  public componentDidUpdate = async (prev: Props) => {
    const { form } = this.props
    if (form.type !== prev.form.type) {
      this.getSubcategories(form.type)
    }
  }

  public render = () => {
    const {
      disableOfficeSelection,
      editing,
      form,
      form: { errors },
      mode
    } = this.props

    const { categories, isReady, offices, subcategories } = this.state

    return (
      <Transition on={isReady}>
        <Container className="rp-transaction-form" height={100}>
          <Scrollable>
            <Section height={100} padding={'0 1em'}>
              {mode !== ModeEnum.Concierge && (
                <Header width={100}>
                  <Image
                    src={GRAPHIC[mode] || GRAPHIC.ManualAdd}
                    max={mode === ModeEnum.Idx ? 180 : 300}
                  />
                  {mode === ModeEnum.Idx && (
                    <Row>
                      <Col width={40}>
                        <Label>Office</Label>
                        <StyledDropdown
                          disabled={
                            disableOfficeSelection || offices.length === 1
                          }
                          error={!!errors.office}
                          fluid={true}
                          name="office"
                          onChange={this.handleInputChange}
                          options={offices}
                          placeholder="Office"
                          search={true}
                          selection={true}
                          value={form.office}
                        />
                      </Col>
                      <Col width={60}>
                        <Label>MLS Listing ID</Label>
                        <StyledInput
                          action={
                            <Submit content="Fetch" onClick={this.getIdxData} />
                          }
                          fluid={true}
                          disabled={disableOfficeSelection}
                          name="mlsId"
                          onChange={this.handleInputChange}
                          placeholder="Listing ID"
                          value={form.mlsId}
                        />
                      </Col>
                    </Row>
                  )}
                </Header>
              )}
              <Section primary={true}>
                <Transition on={isReady} stagger={30}>
                  <Row
                    childWidths={
                      mode === ModeEnum.Idx || mode === ModeEnum.Concierge
                        ? 50
                        : 33.334
                    }
                  >
                    {mode !== ModeEnum.Idx &&
                      mode !== ModeEnum.Concierge && (
                        <Col>
                          <Label>Office</Label>
                          <StyledDropdown
                            disabled={
                              disableOfficeSelection || offices.length === 1
                            }
                            error={!!errors.office}
                            fluid={true}
                            name="office"
                            onChange={this.handleInputChange}
                            options={offices}
                            placeholder="Office"
                            search={true}
                            selection={true}
                            value={form.office}
                          />
                        </Col>
                      )}
                    <Col>
                      <Label>Category</Label>
                      <StyledDropdown
                        error={!!errors.type}
                        fluid={true}
                        name="type"
                        onChange={this.handleCategoryChange}
                        options={categories}
                        placeholder="Category"
                        search={true}
                        selection={true}
                        value={form.type}
                      />
                    </Col>
                    <Col>
                      <Label>Subcategory</Label>
                      <StyledDropdown
                        error={!!errors.subType}
                        fluid={true}
                        name="subType"
                        onChange={this.handleInputChange}
                        options={subcategories}
                        placeholder="Subcategory"
                        search={true}
                        selection={true}
                        value={form.subType}
                      />
                    </Col>
                  </Row>
                  <Row childWidths={50}>
                    <Col>
                      <Label>Transaction Type</Label>
                      <StyledDropdown
                        error={!!errors.transactionRole}
                        fluid={true}
                        name="transactionRole"
                        onChange={this.handleInputChange}
                        options={ROLE_OPTIONS}
                        placeholder="Transaction Role"
                        search={true}
                        selection={true}
                        value={form.transactionRole}
                      />
                    </Col>
                    <Col>
                      <Label>Price</Label>
                      <StyledField
                        control={StyledInput}
                        error={!!errors.price}
                        fluid={true}
                        name="price"
                      >
                        <NumberFormat
                          icon="dollar"
                          onValueChange={this.handlePriceChange}
                          placeholder="$"
                          prefix={'$'}
                          thousandSeparator={true}
                          value={form.price}
                        />
                      </StyledField>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label>Street Address</Label>
                      <AddressInput
                        name="streetAddress"
                        placeholder="Street Address"
                        address={form as AddressType}
                        onChange={this.handleAddressChange}
                        error={!!errors.streetAddress}
                      />
                    </Col>
                  </Row>
                  <Row childWidths={33}>
                    <Col>
                      <Label>City</Label>
                      <AddressInput
                        mode={AddressModeEnum.City}
                        name="city"
                        placeholder="City"
                        address={form as AddressType}
                        onChange={this.handleCityChange}
                        error={!!errors.city}
                      />
                    </Col>
                    <Col>
                      <Label>State</Label>
                      <StateDropdown
                        placeholder="State"
                        name="state"
                        value={form.state}
                        onChange={this.handleInputChange}
                        error={!!errors.state}
                      />
                    </Col>
                    <Col>
                      <Label>Zipcode</Label>
                      <StyledInput
                        className="zipcode"
                        type="text"
                        placeholder="Zip Code"
                        name="zipCode"
                        value={form.zipCode}
                        onChange={this.handleInputChange}
                        error={!!errors.zipCode}
                        />
                    </Col>
                  </Row>
                  { // simple submit button for editing existing entry
                    !!editing ? 
                  <Row justify='flex-end'>
                      <Submit
                        onClick={this.handleSubmit}
                        content={
                          mode === ModeEnum.Concierge
                            ? 'Save and Continue'
                            : 'Save'
                        }
                      />
                  </Row>
                      :
                    // commission fields for new entry
                  <Row align='flex-end'>
                    <Col width={30}>
                      <Label>Expected Commission</Label>
                      <StyledField
                        control={StyledInput}
                          className='amount'
                          error={!!errors.amount}
                          name='fee'
                          >
                        <NumberFormat
                          icon={this.fixedOrPercent('dollar', 'percent')}
                          onValueChange={this.handleFeeAmountChange}
                          placeholder={this.fixedOrPercent('$', '%')}
                          prefix={this.fixedOrPercent('$')}
                          suffix={this.fixedOrPercent(undefined, '%')}
                          thousandSeparator={true}
                          value={form.feeObj?.fee}
                        />
                    </StyledField>
                    </Col>
                    <Col width={20}>
                        <StyledDropdown
                          error={!!errors.currency}
                          fluid={true}
                          name='type'
                          onChange={this.handleFeeCurrencyChange}
                          options={options}
                          placeholder='$'
                          search={true}
                          selection={true}
                          value={form.feeObj?.type}
                        />
                    </Col>
                    <Col width={50}>
                      <Submit
                        onClick={this.handleSubmit}
                        content='Save'
                      />
                    </Col>
                  </Row>
                  }
                </Transition>
              </Section>
            </Section>
          </Scrollable>
        </Container>
      </Transition>
    )
  }

  // returns a value compliant with the actual fee type setting. Defaults on fixed
  private fixedOrPercent(fixed?: string, percent?: string): string | undefined {
    const { form } = this.props
    if (form.feeObj?.type === PaymentNumericEnum.Percent) {
      return percent      
    }
    return fixed
  }

  private handleSubmit = () => {
    const { onAction } = this.props
    onAction(ActionEnum.Submit, null)
  }

  private handleAddressChange = (address: AddressType) => {
    const { onAction } = this.props
    const data = { ...address }
    delete data.country
    
    Object.keys(data).forEach((key: string) => {
      onAction(ActionEnum.UpdateOne, { name: key, value: data[key] })
    })
  }
  

  private handleCityChange = (address: AddressType) => {
    const { form, onAction } = this.props
    const data: any = {
      city: address.city,
      state: address.state
    }
    if (!form.zipCode) {
      data.zipCode = address.zipCode || ''
    }

    Object.keys(data).forEach((key: string) => {
      onAction(ActionEnum.UpdateOne, { name: key, value: data[key] })
    })
  }

  private handleCategoryChange = (
    e: React.SyntheticEvent,
    { name, value }: any
    ) => {
      const { onAction } = this.props
      onAction(ActionEnum.UpdateOne, { name, value })

      this.getSubcategories(value)
    }

  private handleFeeAmountChange = async ({ floatValue }: any) => {
    this.handleFeeChange({
      fee: floatValue
    })
  }

  private handleFeeChange = async (feeProperty: Partial<CommissionItemValueType>) => {
    const { onAction, form: { feeObj } } = this.props
    onAction(ActionEnum.UpdateOne, {
      name: 'feeObj', value: { ...feeObj, ...feeProperty }
    })
  }

  private handleFeeCurrencyChange = (e: React.SyntheticEvent<HTMLInputElement>, { name, value }: any) => {
    this.handleFeeChange({
      type: value
    })
  }

  private handlePriceChange = ({ floatValue }: any) => {
    const { onAction } = this.props
    onAction(ActionEnum.UpdateOne, { name: 'price', value: floatValue })
  }

  private handleInputChange = (e: any, { name, value }: any) => {
    const { onAction } = this.props
    onAction(ActionEnum.UpdateOne, { name, value })
  }

  private getSubcategories = async (id: string) => {
    const { token } = this.props
    const results = await getTransactionSubcategories(id, token)
    if (!results) {
      return
    }
    const subcategories = results.map((item: SubcategoryType) => ({
      key: item._id,
      text: item.name,
      value: item._id
    }))
    this.setState({ subcategories })
  }

  private getIdxData = async () => {
    const { disableOfficeSelection, form, mode, onAction, token } = this.props

    if (disableOfficeSelection || mode !== ModeEnum.Idx) {
      return
    }

    onAction(ActionEnum.Loading, 'Gathering IDX data...')

    let listing: any = {}
    try {
      listing = await getIdxData(form.mlsId, form.office, token)
    } catch (e) {
      serverToast(e)
      onAction(ActionEnum.Loading, null)
      return
    }

    const {
      propertyId: {
        address,
        bathrooms,
        bedrooms,
        description,
        mlsId,
        price,
        squareFt,
        subType,
        type
      },
      transactionRole
    } = listing

    const update: any = {
      bathrooms,
      bedrooms,
      city: address.city,
      description,
      mlsId,
      price,
      state: address.state,
      streetAddress: `${address.streetNumber} ${address.streetName}`,
      streetName: address.streetName,
      streetNumber: address.streetNumber,
      squareFt,
      subType: subType._id,
      transactionRole,
      type: type._id,
      zipCode: address.zipCode
    }

    if (update.type) {
      await this.getSubcategories(update.type)
    }

    for (const key of Object.keys(update)) {
      onAction(ActionEnum.UpdateOne, { name: key, value: update[key] })
    }
    onAction(ActionEnum.Loading, null)
  }
}

export default TransactionForm
