import styled, { css } from 'styled-components'

import Colors from 'design/Colors'
import {
  children,
  childWidths,
  fa,
  x,
  y,
} from 'design/Styled'

import { PaymentTypeEnum } from 'store/Transactions/Types'

interface Props {
  amount?: number
  isActive?: boolean
  type?: PaymentTypeEnum
  custom?: {
    hasChildren?: boolean
  }
}

const getAmountColor = (amount: number | undefined): string => {
  if (null == amount || '0' === Number(amount).toFixed() ) return Colors.TextNeutral
  return Number(amount) > 0 ? Colors.TextPositive : Colors.TextNegative
}

const getType = ({ type, amount }: Props) => {
  let color = Colors.TextNeutral
  switch (type) {
    case PaymentTypeEnum.Credit:
      color = Colors.TextPositive
      break

    case PaymentTypeEnum.Debit:
      color = Colors.TextNegative
      break

    default:
        color = getAmountColor(amount)
      break
  }
  return css`
    color: ${color};
  `
}

const getDollar = ({ amount }: Props) => {
  if (!amount && amount !== 0) {
    return ``
  }
  return css`
    &:after {
      display: block;
      content: '$${Number(amount).toFixed(2).toLocaleString()}';
    }
  `
}

const getStatus = ({ isActive, custom }: Props) => {
  if (custom && custom.hasChildren) {
    return css`
      &:before {
        ${fa};
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0.6em;
        height: 100%;
        content: '\f0da';
        color: ${Colors.Text};
        transition: all 200ms;
        transform: ${isActive ? `rotate(90deg)` : `rotate(0)`};
      }
    `
  }
  return ``
}

export const Span = styled.span`
  font-weight: bold;
  min-width: 100px;
  padding: 0 0.2em;
  text-align: right;
  ${getType};
`

export const Dollar = styled(Span)`
  ${getDollar};
`

export const Col = styled.div`
  ${x};
  ${children};
  width: auto;
`

export const Row = styled.div`
  ${x};
  ${children};
  ${childWidths};
`

export const Link = styled.span`
  text-decoration: underline;
  cursor: pointer;
`

export const Line = styled.div`
  ${x};
  ${children};
  ${childWidths};
  width: 100%;
  padding: 1em;
  padding-left: 2.2em;
  border-bottom: 1px solid ${Colors.TableLine};

  &:hover {
    background: ${Colors.ListOdd};
  }
`

export const HeadLine = styled(Line)`
  position: relative;
  cursor: pointer;
  color: ${Colors.TextPrimary};
  font-weight: bold;
  background: ${Colors.ListOdd};
  border-bottom: 1px solid ${Colors.TableHeadLine};

  &:hover {
    background: ${Colors.ListEven};
  }

  ${getStatus};
`

export const ChildLines = styled.section`
  ${y};
`
