import styled from 'styled-components'
import { Dropdown, Input } from 'semantic-ui-react'

import Colors from 'design/Colors'
import { children, row, x, y } from 'design/Styled'

export const Container = styled.section`
  ${y};
  margin-top: 1em;
  padding: 1em;
  background: ${Colors.Container};

  .ui.selection.dropdown,
  .ui.input,
  .ui.labeled.input>.label,
  input {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.85em !important;
    min-height: 33px;
  }

  .ui.dropdown {
    padding: 0 !important;
  }

  .dropdown > .text,
  input {
    padding: 0.6rem 2.1rem 0.6rem 1rem !important;
  }

  .ui.selection.dropdown > .text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
  }
`

export const Row = styled.div`
  ${row};
  ${children};
  margin-bottom: 0.8em;
`

export const Item = styled.div`
  ${x};
  flex: 1;
`

export const Label = styled.label`
  ${x};
  max-width: 200px;
  color: ${Colors.Text};
`

export const StyledDropdown = styled(Dropdown)``
export const StyledInput = styled(Input)``
