// Import Packages
import { Form, Input, Menu, Button } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'
import { x, row, text, primaryStyledButton } from 'design/Styled'

export const CloseWrapper = styled.div`
  height: 100vh;
  position: fixed;
  width: 100%;
  left: 0;
`

export const StyledContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 40px;
  left: 0;
  margin-top: 0px;
  z-index: 1;
  background-color: white;
  border-radius: 4px;
  box-shadow: 1px 3px 10px 2px rgba(0, 0, 0, 0.2);
`

export const PopUpContent = styled.div`
  padding: 0 !important;
`

export const Container = styled.div`
  display: flex;
  color: ${Colors.Black500};
`

export const Filters = styled.div`
  width: 35%;
  position: relative;
  background: ${Colors.Grey110};
`

export const FiltersTitle = styled.div`
  font-size: 10px;
  margin-top: 15px;
  text-align: center;
`

export const StyledMenu = styled(Menu)`
  &.ui.vertical.menu {
    background-color: ${Colors.transparent};
    box-shadow: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    margin-left: 15px;
    cursor: pointer;
    width: 84%;
    border-top-color: ${Colors.DarkBlue275};
    border-bottom-color: ${Colors.DarkBlue275};
    margin-bottom: 30px;
  }

  &.ui.vertical.menu .item:before {
    background: ${Colors.DarkBlue275};
  }

  &.ui.vertical.menu .item {
    color: ${Colors.Black550};
    font-size: 12px;
  }

  &.ui.vertical.menu .active.item {
    background: ${Colors.transparent};
    border-radius: 0;
    color: ${Colors.DarkBlue200};
    font-weight: bold;
  }
`

export const StyledForm = styled(Form)`
  width: 70%;
  padding: 30px;

  &.ui.form .field > label {
    font-size: 12px;
    color: ${Colors.Black550};
  }

  .algolia > .ui.input,
  .algolia > .rp-state-dropdown {
    margin-bottom: 12px;
  }

  .ui.button {
    background: ${Colors.DarkBlue200};
    border-radius: 3px;
    color: ${Colors.White1000};
  }

  a.ui.label {
    color: ${Colors.Blue60};
    position: relative;
    background-color: ${Colors.White490};
    font-size: 10px;
    min-width: 38px;
    width: auto;
    margin-bottom: 5px;
  }

  .fa-times {
    position: absolute;
    top: -4px;
    right: 2px;
    height: 10px;
    width: 8px;
    color: ${Colors.Black530};
  }

  .ui.action.input:not([class*='left action']) > .button:last-child,
  .ui.action.input:not([class*='left action']) > .buttons:last-child > .button,
  .ui.action.input:not([class*='left action']) > .dropdown:last-child {
    background: transparent;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-left: 0;
    width: 40px;
  }

  .ui.icon.button > .icon,
  .ui.icon.buttons .button > .icon {
    color: ${Colors.Black400};
  }
`

export const Type = styled.div`
  margin-bottom: 20px;

  .type {
    font-size: 12px;
    color: ${Colors.Black550};
    font-weight: 700;
    margin-bottom: 6px;
  }

  .icons {
    display: flex;
  }

  .fa-envelope {
    color: ${Colors.Red20};
    width: 20px;
    height: 20px;
    margin-right: 20px;
  }

  .fa-sms {
    color: ${Colors.Pink70};
    width: 20px;
    height: 20px;
  }

  .fa-phone {
    color: ${Colors.Yellow70};
    transform: rotate(100deg);
    width: 20px;
    height: 20px;
    margin-right: 20px;
  }
`

export const SearchContainer = styled(Form)`
  ${row};
  font-size: 12px;
  border-radius: 0.28571429rem;
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.06);

  &.ui.form input[type='text'] {
    ${text};
    border: none;
    box-shadow: none;
    outline: none;
  }
`

export const SearchInput = styled(Input)`
  ${x};
  flex: 1;
  &.ui.input {
    ${row};
    align-items: stretch;
    & > input {
      ${text};
      padding-left: 0.5em;
      padding-right: 0.5em;
      border: none;
      box-shadow: none;
      outline: none;
    }
    .rp-button-bubble {
      margin: 0 0.5em;
      align-self: center;
    }
    .ui.button {
      padding: 0.5em 1em;
      min-width: 0;
    }
  }
`

export const Submit = styled(Button)`
  ${primaryStyledButton};
`

export const Tag = styled.div`
  background: ${Colors.DarkBlue200};
  padding: 3px 20px 3px 8px;
  font-size: 14px;
  color: ${Colors.White1000};
  position: relative;
  white-space: nowrap;
  margin-right: 5px;

  .times {
    position: absolute;
    top: -3px;
    right: 3px;
  }

  .times > .fa-times {
    width: 10px;
    height: 10px;
    cursor: pointer;
  }
`

export const Plus = styled.div`
  display: flex;
  align-items: center;
  margin-left: 6px;
  color: ${Colors.Black550};
`

export const TagContainer = styled.div`
  display: flex;
  overflow: auto;
  max-width: 250px;
  padding: 0 0.5em;

  ::-webkit-scrollbar {
    height: 3px;
  }

  @media (max-width: 1200px) {
    max-width: 150px;
  }
  @media (min-width: 1200px) and (max-width: 1400px) {
    max-width: 250px;
  }
`

export const SearchWrapper = styled.div`
  width: 40%;
  position: relative;
`

export const Filter = styled.div`
  font-size: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-weight: 600;
  color: ${Colors.DarkBlue205};
`

export const StyledInput = styled(Input)``