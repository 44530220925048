// Import Packages
import styled from 'styled-components'

// Import Images and Icons
import { next } from 'design/icons/dashboard'

// Import Colors
import Colors from 'design/Colors'
import { scrollbars } from 'design/Styled'

interface FlexProps {
  spacing: string
}

const TransactionContainer = styled.div`
  width: 100%;
  cursor: pointer;
  transition: all 0.2s;
  opacity: 1;
  &:hover {
    opacity: 0.65;
  }

  .more {
    text-align: center;
    color: ${Colors.TextLight};
    font-weight: bold;
    text-decoration: underline;
  }
`

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${Colors.Container};
  opacity: .6;
  margin: 15px 0;
  @media(max-width: 1100px) {
    width: 98%;
  }
`

const NextIcon = styled.div`
  width: 20px;
  height: 20px;
  background-image: url(${next});
  background-size: contain;
  background-repeat: no-repeat;
`

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  width: 87%;
  position: relative;
  justify-content: ${(props: FlexProps) => props.spacing};

  .fa-chevron-right {
    width: 10px;
    height: 10px;
    color: ${Colors.TextLight};
  }
`

const Image = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
  object-position: center;
  margin-right: 10px;
  border-radius: 30px;
`

const ImageIcon = styled.div`
  position: absolute;
  font-size: 10px;
  font-weight: 600;
  color: ${Colors.TextLight};
  background-color: ${Colors.ButtonPrimary};
  border-radius: 50px;
  width: 17px;
  height: 16px;
  top: 23px;
  left: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Header = styled.div`
  font-size: 14px;
  color: ${Colors.TextLight};
  font-weight: 500;
  text-transform: capitalize;
`

const SubHeader = styled.div`
  font-size: 10px;
  font-weight: 500;
  color: ${Colors.TextLight};
`

const Lable = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: ${Colors.ButtonDanger};
  text-align: center;
  color: ${Colors.TextLight};
  font-weight: bold;
  margin-left: 6px;
  font-style: normal;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Container = styled.div`
  max-height: calc(100vh - 340px);
  overflow: auto;
  overflow-x: hidden;
  ${scrollbars};

  &.add-to-list.slide-fade li {
    opacity: 0;
  }

  &.add-to-list.slide-fade li.show {
    opacity: 1;
  }

  &.add-to-list li.show {
    height: 4.7em;
  }

  &.add-to-list li {
    height: 0;
    list-style: none;
  }

  .more {
    text-align: center;
    color: ${Colors.TextLight};
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }
`

export {
  Container,
  NextIcon,
  TransactionContainer,
  Lable,
  Line,
  FlexContainer,
  SubHeader,
  Header,
  Image,
  ImageIcon
}
