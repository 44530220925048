import { PaymentNumericEnum } from 'store/Transactions/Types'
import {
  FormType,
  FormErrorType
} from './Types'

export const handleValidation = (form: FormType) => {
  const errors = {} as FormErrorType
  let formIsValid = true

  if (!form.name.trim()) {
    formIsValid = false
    errors.name = 'Item name can not be blank'
  }

  if (!form.fee) {
    formIsValid = false
    errors.fee = 'You must include an item amount'
  }

  if (form.type === PaymentNumericEnum.Percent && form.fee > 100) {
    formIsValid = false
    errors.fee = 'Percentage can not be greater than 100'
  }

  if (!form.type) {
    formIsValid = false
    errors.type = 'Please select an amount type'
  }

  return {
    errors,
    formIsValid,
  }
}
