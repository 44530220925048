import gql from 'graphql-tag'

export const GET_USERS = gql`
  query getUsers($isActive: Boolean, $filter: UserSearch) {
    getUsers(where: { isActive: $isActive }, filter: $filter) {
      _id
      firstName
      lastName
      emails {
        value
        type
      }
      office {
        _id
        isDefault
        isActive
        branchName
      }
      capSetting {
        _id
        name
      }
      userName
      role
      notificationSetting {
        category
        key
        title
        inApp
        email
      }
      userCap {
        residentialTargetCap
        commercialTargetCap
        residentialCompletedCap
        commercialCompletedCap
        residentialCapStatus
        commercialCapStatus
      }
      addresses {
        streetNumber
        streetName
        city
        type
        state
        zipCode
      }
      status
      phones {
        type
        value
      }
      teams {
        _id
        name
      }
      profileImage
      transactionFeeSetting {
        _id
        name
      }
      createdAt
      isLoggedIn @client
    }
  }
`

export const GET_OFFICE_USERS = gql`
  query getOfficeUsers {
    getUsers(
      where: {
        isActive: true
        isPrimaryManager: false
        status: "Active"
        hasTeam: false
      }
    ) {
      _id
      firstName
      lastName
      phones {
        value
      }
      emails {
        value
      }
      role
      office {
        _id
        isDefault
        isActive
        branchName
      }
    }
  }
`

export const GET_TEAM_USERS = gql`
  query getTeamUsers($officeId: [String], $hasTeam: Boolean) {
    getUsers(where: { office: $officeId, isActive: true, hasTeam: $hasTeam }) {
      _id
      firstName
      lastName
      emails {
        value
      }
      role
      office {
        _id
        isDefault
        isActive
        branchName
      }
    }
  }
`

export const GET_PRE_SIGNED_URL = gql`
  query preSignedUrl($url: String) {
    preSignedUrl(s3Url: $url)
  }
`

export const GET_USERS_ON_GROUP = gql`
  query getUsersOnGroup(
    $id: String
    $isActive: Boolean
    $filter: UserSearch
    $office: [String]
  ) {
    getUsers(
      where: { isActive: $isActive, group: $id, office: $office }
      filter: $filter
    ) {
      _id
      firstName
      lastName
      emails {
        value
        type
      }
      office {
        _id
        isDefault
        isActive
        branchName
      }
      notificationSetting {
        category
        key
        title
        inApp
        email
      }
      capSetting {
        _id
        name
      }
      userName
      role
      userCap {
        residentialTargetCap
        commercialTargetCap
        residentialCompletedCap
        commercialCompletedCap
        residentialCapStatus
        commercialCapStatus
      }
      addresses {
        streetNumber
        streetName
        city
        state
        zipCode
      }
      status
      phones {
        type
        value
      }
      teams {
        _id
        name
      }
      profileImage
      transactionFeeSetting {
        _id
        name
      }
      isLoggedIn @client
    }
  }
`

export const GET_USER_DETAIL = gql`
  query getUserDetails($userId: ID) {
    getUsers(where: { _id: $userId, isActive: true }) {
      _id
      firstName
      lastName
      userName
      office {
        _id
      }
      teams {
        _id
      }
    }
  }
`

export const GET_OFFICE_MANAGERS = gql`
  query getOfficeManager($officeId: [String]) {
    getUsers(where: { office: $officeId, role: "MANAGER" }) {
      _id
      firstName
      lastName
    }
  }
`

export const GET_REASSIGN_OFFICE_USERS = gql`
  query getReassignOfficeUsers {
    getOfficeUsers(where: { isActive: true }) {
      _id
      firstName
      lastName
      userName
      profileImage
      role
      status
      type
      phones {
        type
        value
      }
      emails {
        value
      }
    }
  }
`

export const GENERIC_GET_USERS = gql`
  query getUsers(
    $where: UserFilter
    $sorting: SortFilter
    $skip: Int
    $limit: Int
    $filter: UserSearch
  ) {
    genericGetUsers: getUsers(
      where: $where
      sorting: $sorting
      skip: $skip
      limit: $limit
      filter: $filter
    ) {
      _id
      firstName
      lastName
      emails {
        value
        type
      }
      office {
        _id
        isDefault
        isActive
        branchName
      }
      notificationSetting {
        category
        key
        title
        inApp
        email
      }
      capSetting {
        _id
        name
      }
      userName
      role
      userCap {
        residentialTargetCap
        commercialTargetCap
        residentialCompletedCap
        commercialCompletedCap
        residentialCapStatus
        commercialCapStatus
      }
      status
      phones {
        type
        value
      }
      teams {
        _id
        name
      }
      profileImage
      transactionFeeSetting {
        _id
        name
      }
      isLoggedIn @client
    }
  }
`

export const GET_NEW_USER_SETTINGS = gql`
  query getNewUserSettings {
    capSettings: getCapSettings(where: { isActive: true }) {
      _id
      name
      capStatus
      individualResidentialCap
      individualCommercialCap
      teamCap
      additionalMemberCap
    }

    transactionSettings: getTransactionSettings(where: { isActive: true }) {
      _id
      name
      isDefault
    }

    groups: getGroups(where: { isActive: true }) {
      _id
      name
      office {
        _id
      }
    }

    offices: getOffices(where: { isActive: true }) {
      _id
      isDefault
      isActive
      branchName
      state
      zipCode
      city
    }
  }
`

export const GET_1099_REPORT = gql`
  query get1099Report($office: String, $userId: String, $year: Int) {
    get1099Report(office: $office, userId: $userId, year: $year)
  }
`
