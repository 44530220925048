// Import Packages
import { Checkbox, Label, Popup } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Images and Icons
import Chat from 'design/icons/teams/chat.png'
import Envalope from 'design/icons/teams/icon-envalope.png'
import Phone from 'design/icons/teams/icon-phone.png'

// Import Colors
import Colors from 'design/Colors'
import { y } from 'design/Styled'

interface Props {
  background?: string
  left?: number
  color?: string
}

export const Container = styled.div`
  ${y};
  padding: 0;
  height: 100%;
  overflow: hidden;
`

const StyledCheckbox = styled(Checkbox)`
  &.ui.checkbox{
    min-width: 0px;
  }
`

const Leader = styled.div`
  display: flex;
  align-items: center;
`

const ProfileImage = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 30px;
  align-self: center;
  margin-right: 6px;
  border-radius: 50%;
`

const FullName = styled.div`
  width: 100px;
  word-break: break-all;
  text-transform:capitalize;
`

const EmailIcon = styled.div`
  background: url(${Envalope});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 12px;
  height: 12px;
  align-self: center;
  margin-left: 20px;
  cursor: pointer;
  text-transform:lowercase;
`

const PhoneIcon = styled.div`
  background: url(${Phone});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 12px;
  height: 12px;
  align-self: center;
  margin-left: 12px;
  cursor: pointer;
`

const ChatIcon = styled.div`
  background: url(${Chat});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 12px;
  height: 12px;
  align-self: center;
  margin-left: 10px;
`
const TeamCAP = styled.div`
  color: ${Colors.Black550};
`
const Member = styled.div`
  display: flex;
  position: relative;
  margin-left: 6px;
`

const MemberProfile = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 25px;
  height: 25px;
  align-self: center;
  position: absolute;
  left: ${(props: Props) => props.left || props.left}px;
  border: 1px solid;
  border-radius: 50%;
  border: 1px solid ${Colors.DarkBlue220};
  cursor: pointer;
`

const Labels = styled.div`
  width: 25px;
  height: 25px;
  background: ${Colors.White1000};
  border: 1px solid ${Colors.DarkBlue220};
  position: absolute;
  border-radius: 50%;
  color: white;
  left: 45px;
  top: -12px;
`

const StyledLabel = styled.div`
  ${y};
  height: 100%;
  color: ${Colors.DarkBlue220};
  align-items: center;
  font-weight: bold;
  cursor: pointer;
`

const Pending = styled.div`
  display: flex;
  cursor: pointer;
`

const Invites = styled(Label)`
  &.ui.circular.label, .ui.circular.labels .label {
    min-width: 1em;
    min-height: 1em;
    line-height: 0.5em;
    margin-left: 5px;
  }

  &.ui.label {
    background: ${Colors.Red810};
    color: ${Colors.White1000};
  }
`

const StyledDropdown = styled.div`
  width: auto;
  min-width: 70px;
  font-size: 10px;
`

const DropDownPopup = styled(Popup)`
  &.ui.bottom.left.popup {
    margin-left: -14px;
    margin-top: 14px;
  }

  &.ui.top.left.popup {
    margin-left: -14px;
    margin-bottom: 6px;
  }

  &.ui.popup {
    padding: 8px;
  }

  .item {
    cursor: pointer;
    padding: 5.5px;
    color: ${Colors.Black500};
    font-size: 10px;
    :hover {
      background: rgba(0,0,0,.05);
      color: rgba(0,0,0,.95);
      z-index: 13;
    }
  }

  .action-drop-down>.menu>a {
    position: relative;
    cursor: pointer;
    display: block;
    border: none;
    height: auto;
    text-align: left;
    border-top: none;
    line-height: 1.5em;
    color: rgba(0,0,0,.87);
    text-transform: none;
    font-size: 10px;
    color: ${Colors.Black500};
    font-weight: 500;

    :hover {
      background: rgba(0,0,0,.05);
      color: rgba(0,0,0,.95);
      z-index: 13;
    }
  }
`

const ErrorContainer = styled.div`
  padding: 30px;
  font-size: 20px;
`

const Error = styled.div`
  font-size: 30px;
  text-align: center;
  color: ${Colors.Red10};
`

const ErrorText = styled.div`
  font-size: 30px;
  color: ${Colors.Black500};
`

const TeamOffice = styled.div`
  text-transform:capitalize;
  cursor: pointer;
`

const CheckboxWrapper = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
`

const StyledPopup = styled(Popup)`
  &.ui.popup {
    padding: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 12px;
    color: ${Colors.Black500};
    text-transform: lowercase;
  }
`

export {
  ChatIcon,
  CheckboxWrapper,
  DropDownPopup,
  Error,
  ErrorContainer,
  ErrorText,
  EmailIcon,
  FullName,
  Invites,
  Labels,
  Leader,
  Member,
  MemberProfile,
  Pending,
  PhoneIcon,
  ProfileImage,
  StyledCheckbox,
  StyledDropdown,
  StyledLabel,
  TeamCAP,
  TeamOffice,
  StyledPopup
}
