import SettingsActions from './Settings/Actions'

const Actions = {
  addWidget: '@@dashboard/ADD_WIDGET',
  dashboardAddCustomeColor: '@@dashboard/ADD_CUSTOME_COLOR',
  dashboardAddNotes: '@@dashboard/ADD_NOTES',
  dashboardAddPass: '@@dashboard/ADD_PASS',
  dashboardAddWallpaperImage: '@@dashboard/ADD_WALLPAPER_IMAGE',
  dashboardAddWidgetSection: '@@dashboard/ADD_WIDGET_SECTIONS',
  dashboardCategoriesFilter: '@@dashboard/CATEGOIES_FILTERS',
  dashboardDeletePass: '@@dashboard/DELETE_PASS',
  dashboardDeleteWidgetSection: '@@dashboard/DELETE_WIDGET_SECTIONS',
  dashboardGetCustomeColor: '@@dashboard/GET_CUSTOME_COLOR',
  dashboardGetNotes: '@@dashboard/GET_NOTES',
  dashboardGetUserProfile: '@@dashboard/GET_USER_PROFILE',
  dashboardGetWallpaperImage: '@@dashboard/GET_WALLPAPER_IMAGE',
  dashboardGetWidgetSection: '@@dashboard/GET_WIDGET_SECTIONS',
  dashboardOfficeID: '@@dashboard/OFFICE_ID',
  dashboardTaskID: '@@dashboard/TASK_ID',
  dashboardRenamePass: '@@dashboard/RENAME_PASS',
  dashboardRenameWidgetSection: '@@dashboard/RENAME_WIDGET_SECTIONS',
  dashboardSetCustomeColor: '@@dashboard/SET_CUSTOME_COLOR',
  dashboardSetThemeImage: '@@dashboard/SET_THEME_IMAGE',
  dashboardSignatureModal: '@@dashboard/SIGNATURE_MODAL',
  dashboardTeamID: '@@dashboard/TEAM_ID',
  fetchAllRecentLeads: '@@leads/GET_ALL_RECENT_LEADS',
  fetchAllRecentTransactions: '@@transactions/GET_ALL_RECENT_TRANSACTIONS',
  renderAllWidgets: '@@dashboard/RENDER_ALL_WIDGETS',
  showChecklistTab: '@@dashboard/SHOW_CHECKLIST_TAB',
  showFeeTab: '@@dashboard/SHOW_FEE_TAB',
  showNotificationTab: '@@dashboard/SHOW_NOTIFICATION_TAB',
  showPricingTab: '@@dashboard/SHOW_PRICING_TAB',
  showTaxTab: '@@dashboard/SHOW_TAX_TAB',
  showWorkflowTab: '@@dashboard/SHOW_WORKFLOW_TAB',
  toggleComposeMail: '@@dashboard/TOGGLE_COMPOSE_MAIL',
  toggleComposeMailForDocument: '@@dashboard/TOGGLE_COMPOSE_MAIL_FOR_DOCUMENT',
  toggleDetailsPopup: '@@dashboard/TOGGLE_DETAILS_POPUP',
  toggleEventsPopup: '@@dashboard/TOGGLE_EVENTS_POPUP',
  toggleNavigation: '@@layout/TOGGLE_NAVIGATION',
  toggleSharedWithPopup: '@@dashboard/TOGGLE_SHARED_WITH_POPUP',
  toggleWidgetsPopup: '@@dashboard/TOGGLE_WIDGETS_POPUP',
  userSectionsChanged: '@@dashboard/USER_SECTIONS_CHANGED',
  ...SettingsActions
}

export default Actions
