import moment from 'moment'
import * as React from 'react'

import BillingHistoryModal from 'shared/Billing/BillingHistoryModal'
import Scrollable from 'shared/Scrollable'
import Transition from 'shared/Transition'

import { Strings } from 'utils'

import { BillingPlanEnum, InvoiceType, LineItemType } from 'shared/Billing/Types'

import {
  Body,
  CancelButton,
  Col,
  Container,
  Footer,
  Header,
  History,
  Invoice,
  Line,
  ManageButton,
  Title,
  Total
} from './Styled'

interface Props {
  invoice: InvoiceType
  onUpdate: (plan: BillingPlanEnum) => void
}

interface State {
  modal: any
}

class Company extends React.Component<Props, State> {
  public state = {
    modal: null
  }

  public render() {
    const {
      invoice: { cycle, lines, total_decimal }
    } = this.props

    const { modal } = this.state

    const isReady: boolean = cycle.plan !== BillingPlanEnum.None

    return (
      <React.Fragment>
        {modal}
        <Container>
          <Transition on={isReady} type="fadeIn">
            <Header>
              <Title>
                <h1>{Strings.billing.title.nextInvoice}</h1>
                <h2>Current Plan: {cycle.plan}</h2>
                <h3>
                  {moment(cycle.startDate).format('ll')} - {moment(cycle.endDate).format('ll')}
                </h3>
              </Title>
              <History onClick={this.showBillingHistory}>{Strings.billing.button.viewHistory}</History>
            </Header>
          </Transition>
          <Body>
            <Scrollable>
              <Transition on={isReady}>
                <Invoice>
                  {lines.map((line: LineItemType, index: number) => (
                    <Transition on={isReady} delay={30 * index + 1} type="fadeDown">
                      <Line>
                        <strong>{line.description}</strong>
                        <span>${line.amount_decimal}</span>
                      </Line>
                    </Transition>
                  ))}
                  <Transition on={isReady} delay={30 * lines.length + 2} type="fadeDown">
                    <Total>
                      <span>{Strings.billing.title.total}</span>
                      <strong>{cycle.plan === BillingPlanEnum.Free ? 'FREE' : `$${total_decimal || 0}`}</strong>
                    </Total>
                  </Transition>
                  <Footer>
                    <small>Please note: {Strings.billing.description.footnote1}</small>
                  </Footer>
                </Invoice>
              </Transition>
            </Scrollable>
          </Body>
          {cycle.plan !== BillingPlanEnum.Free && (
            <Transition on={isReady} type="fadeIn">
              <Footer childWidths={50}>
                <Col>
                  <ManageButton content="Manage" onClick={this.handleManagePlan} />
                </Col>
                <Col>
                  <CancelButton content="Cancel" onClick={this.handleCancelPlan} />
                </Col>
              </Footer>
            </Transition>
          )}
        </Container>
      </React.Fragment>
    )
  }

  private handleCancelPlan = () => {
    const { onUpdate } = this.props
    onUpdate(BillingPlanEnum.Free)
  }

  private handleManagePlan = () => {
    const { onUpdate } = this.props
    onUpdate(BillingPlanEnum.Paid)
  }

  private showBillingHistory = () => {
    const closeModal = () => {
      this.setState({ modal: null })
    }
    const modal = <BillingHistoryModal onClose={closeModal} />
    this.setState({ modal })
  }
}

export default Company
