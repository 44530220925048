// Import Utils
import { generateID } from 'utils'

export const transactionMockData = [
  {
    _id: generateID(),
    fieldCategory: 'Address',
    fieldId: '',
    fieldName: [
      { _id: generateID(), field: 'Street No', fieldId: 'propertyId.address.streetNumber', isChecked: false },
      { _id: generateID(), field: 'Street Name', fieldId: 'propertyId.address.streetName', isChecked: false },
      { _id: generateID(), field: 'State', fieldId: 'propertyId.address.state', isChecked: false },
      { _id: generateID(), field: 'City', fieldId: 'propertyId.address.city', isChecked: false },
      { _id: generateID(), field: 'Zipcode', fieldId: 'propertyId.address.zipCode', isChecked: false },
    ],
    isChecked: false,
  },
  {
    _id: generateID(),
    fieldCategory: 'Transaction Role',
    fieldId: 'transactionRole',
    fieldName: [],
    isChecked: false,
  },
  {
    _id: generateID(),
    fieldCategory: 'Category',
    fieldId: 'propertyId.type.name',
    fieldName: [],
    isChecked: false,
  },
  {
    _id: generateID(),
    fieldCategory: 'Price',
    fieldId: 'propertyId.price',
    fieldName: [],
    isChecked: false,
  },
  {
    _id: generateID(),
    fieldCategory: 'Status',
    fieldId: 'status',
    fieldName: [],
    isChecked: false,
  },
  {
    _id: generateID(),
    fieldCategory: 'Paid Date',
    fieldId: 'payment.receivedDate',
    fieldName: [],
    isChecked: false,
  },
  {
    _id: generateID(),
    fieldCategory: 'Important Dates',
    fieldId: '',
    fieldName: [
      { _id: generateID(), field: 'Contract Date', fieldId: '', isChecked: false },
      { _id: generateID(), field: 'Created Date', fieldId: 'createdAt', isChecked: false }
    ],
    isChecked: false,
  },
  {
    _id: generateID(),
    fieldCategory: 'Transaction',
    fieldId: '',
    fieldName: [
      { _id: generateID(), field: 'ID', fieldId: 'transactionId', isChecked: false },
      { _id: generateID(), field: 'MLS ID', fieldId: 'propertyId.mlsId', isChecked: false },
      { _id: generateID(), field: 'Owner First Name', fieldId: 'owner.firstName', isChecked: false },
      { _id: generateID(), field: 'Owner Last Name', fieldId: 'owner.lastName', isChecked: false },
    ],
    isChecked: false,
  },
  {
    _id: generateID(),
    fieldCategory: 'Financial Summary',
    fieldId: '',
    fieldName: [
      { _id: generateID(), field: 'Expected Commission', fieldId: 'payment.expectedCommission', isChecked: false },
      { _id: generateID(), field: 'Commission Received', fieldId: 'payment.actualCommission', isChecked: false },
      { _id: generateID(), field: 'Total Debits', fieldId: 'payment.totalDebits', isChecked: false },
      { _id: generateID(), field: 'Total Credits', fieldId: 'payment.totalCredits', isChecked: false },
      {_id: generateID(), field: 'Pay Via Escrow',fieldId: 'payment.payViaEscrowAmount',isChecked: false},
      { _id: generateID(), field: 'Total Referrals', fieldId: 'payment.totalReferrals', isChecked: false },
      // { _id: generateID(), field: 'Total Due', fieldId: 'payment.totalIncome', isChecked: false },
    ],
    isChecked: false,
  },
  {
    _id: generateID(),
    fieldCategory: 'Total Due',
    fieldId: 'payment.commissionDue',
    fieldName: [],
    isChecked: false,
  },
]

export const leadMockData = [
  {
    _id: generateID(),
    fieldCategory: 'First Name',
    fieldId: 'firstName',
    fieldName: [],
    isChecked: true,
  },
  {
    _id: generateID(),
    fieldCategory: 'Last Name',
    fieldId: 'lastName',
    fieldName: [],
    isChecked: true,
  },
  {
    _id: generateID(),
    fieldCategory: 'Email',
    fieldId: 'email',
    fieldName: [],
    isChecked: false,
  },
  {
    _id: generateID(),
    fieldCategory: 'Phone Number',
    fieldId: 'phone',
    fieldName: [],
    isChecked: false,
  },
  {
    _id: generateID(),
    fieldCategory: 'Property Type',
    fieldId: 'propertyType',
    fieldName: [],
    isChecked: false,
  },
  {
    _id: generateID(),
    fieldCategory: 'Lead Type',
    fieldId: 'type',
    fieldName: [],
    isChecked: false,
  },
  {
    _id: generateID(),
    fieldCategory: 'Source',
    fieldId: 'source',
    fieldName: [],
    isChecked: false,
  },
  {
    _id: generateID(),
    fieldCategory: 'Status',
    fieldId: 'status',
    fieldName: [],
    isChecked: false,
  },
]

export const contactMockData = [
  {
    _id: generateID(),
    fieldCategory: 'First Name',
    fieldId: 'firstName',
    fieldName: [],
    isChecked: true,
  },
  {
    _id: generateID(),
    fieldCategory: 'Last Name',
    fieldId: 'lastName',
    fieldName: [],
    isChecked: true,
  },
  {
    _id: generateID(),
    fieldCategory: 'Email',
    fieldId: 'email',
    fieldName: [],
    isChecked: false,
  },
  {
    _id: generateID(),
    fieldCategory: 'Phone Number',
    fieldId: 'phone',
    fieldName: [],
    isChecked: false,
  },
  {
    _id: generateID(),
    fieldCategory: 'Source',
    fieldId: 'source',
    fieldName: [],
    isChecked: false,
  },
  {
    _id: generateID(),
    fieldCategory: 'Type',
    fieldId: 'type',
    fieldName: [],
    isChecked: false,
  },
  {
    _id: generateID(),
    fieldCategory: 'Date Of Birth',
    fieldId: 'dateOfBirth',
    fieldName: [],
    isChecked: false,
  },
  {
    _id: generateID(),
    fieldCategory: 'Anniversary Date',
    fieldId: 'anniversaryDate',
    fieldName: [],
    isChecked: false,
  },
  {
    _id: generateID(),
    fieldCategory: 'Address',
    fieldId: '',
    fieldName: [
      { _id: generateID(), field: 'Street No', fieldId: 'streetNumber', isChecked: false },
      { _id: generateID(), field: 'Street Name', fieldId: 'streetName', isChecked: false },
      { _id: generateID(), field: 'State', fieldId: 'city', isChecked: false },
      { _id: generateID(), field: 'City', fieldId: 'state', isChecked: false },
      { _id: generateID(), field: 'Zipcode', fieldId: 'zipCode', isChecked: false },
    ],
    isChecked: false,
  },
]

export const userMockData = [
  {
    _id: generateID(),
    fieldCategory: 'First Name',
    fieldId: 'firstName',
    fieldName: [],
    isChecked: true,
  },
  {
    _id: generateID(),
    fieldCategory: 'Last Name',
    fieldId: 'lastName',
    fieldName: [],
    isChecked: true,
  },
  {
    _id: generateID(),
    fieldCategory: 'Status',
    fieldId: 'status',
    fieldName: [],
    isChecked: false,
  },
  {
    _id: generateID(),
    fieldCategory: 'Email',
    fieldId: 'emails[0].value',
    fieldName: [],
    isChecked: false,
  },
  {
    _id: generateID(),
    fieldCategory: 'Phone Number',
    fieldId: 'phones[0].value',
    fieldName: [],
    isChecked: false,
  },
  {
    _id: generateID(),
    fieldCategory: 'Role',
    fieldId: 'role',
    fieldName: [],
    isChecked: false,
  },
  {
    _id: generateID(),
    fieldCategory: 'Commission Plan',
    fieldId: 'transactionFeeSetting.name',
    fieldName: [],
    isChecked: false,
  },
  {
    _id: generateID(),
    fieldCategory: 'Cap Setting',
    fieldId: 'capSetting.name',
    fieldName: [],
    isChecked: false,
  },
  {
    _id: generateID(),
    fieldCategory: 'Home Address',
    fieldId: '',
    fieldName: [
      { _id: generateID(), field: 'Street No', fieldId: 'homeAddress.streetNumber', isChecked: false },
      { _id: generateID(), field: 'Street Name', fieldId: 'homeAddress.streetName', isChecked: false },
      { _id: generateID(), field: 'State', fieldId: 'homeAddress.state', isChecked: false },
      { _id: generateID(), field: 'City', fieldId: 'homeAddress.city', isChecked: false },
      { _id: generateID(), field: 'Zipcode', fieldId: 'homeAddress.zipCode', isChecked: false },
    ],
    isChecked: false,
  },
]

export const teamMockData = [
  {
    _id: generateID(),
    fieldCategory: 'Team Name',
    fieldId: 'name',
    fieldName: [],
    isChecked: false,
  },
  {
    _id: generateID(),
    fieldCategory: 'Office',
    fieldId: 'office.branchName',
    fieldName: [],
    isChecked: false,
  },
  {
    _id: generateID(),
    fieldCategory: 'Team Leader',
    fieldId: 'leader.firstName',
    fieldName: [],
    isChecked: false,
  },
  {
    _id: generateID(),
    fieldCategory: 'Team CAP',
    fieldId: '',
    fieldName: [
      { _id: generateID(), field: 'Residential', fieldId: 'teamCap.residentialTargetCap', isChecked: false },
      { _id: generateID(), field: 'Commercial', fieldId: 'teamCap.commercialTargetCap', isChecked: false },

    ],
    isChecked: false,
  }
]

export const teamMockDataForCapOff = [
  {
    _id: generateID(),
    fieldCategory: 'Team Name',
    fieldId: 'name',
    fieldName: [],
    isChecked: false,
  },
  {
    _id: generateID(),
    fieldCategory: 'Office',
    fieldId: 'office.branchName',
    fieldName: [],
    isChecked: false,
  },
  {
    _id: generateID(),
    fieldCategory: 'Team Leader',
    fieldId: 'leader.firstName',
    fieldName: [],
    isChecked: false,
  }
]

export const officeMockData = [
  {
    _id: generateID(),
    fieldCategory: 'Branch Name',
    fieldId: 'branchName',
    fieldName: [],
    isChecked: false,
  },
  {
    _id: generateID(),
    fieldCategory: 'Active User',
    fieldId: 'status',
    fieldName: [],
    isChecked: false,
  },
  {
    _id: generateID(),
    fieldCategory: 'Street Address',
    fieldId: '',
    fieldName: [
      { _id: generateID(), field: 'State', fieldId: 'state', isChecked: false },
      { _id: generateID(), field: 'City', fieldId: 'city', isChecked: false },
      { _id: generateID(), field: 'Zipcode', fieldId: 'zipCode', isChecked: false },
    ],
    isChecked: false,
  },
]

export const taskMockData = [
  {
    _id: generateID(),
    fieldCategory: 'Task Name',
    fieldId: 'name',
    fieldName: [],
    isChecked: false,
  },
  {
    _id: generateID(),
    fieldCategory: 'Description',
    fieldId: 'description',
    fieldName: [],
    isChecked: false,
  },
  {
    _id: generateID(),
    fieldCategory: 'Type',
    fieldId: 'type',
    fieldName: [],
    isChecked: false,
  },
  {
    _id: generateID(),
    fieldCategory: 'Due Date',
    fieldId: 'dueDate',
    fieldName: [],
    isChecked: false,
  },
  {
    _id: generateID(),
    fieldCategory: 'Shared With',
    fieldId: 'sharedWith',
    fieldName: [],
    isChecked: false,
  },
]
