import { CommissionItemValueType } from 'app/Transactions/Details/Types'
import { AddressType } from 'shared/AddressInput/Types'

type ID = string

export interface FormType {
  _id: ID
  accountNumber: string
  address: AddressType
  bankName: string
  brokerageName: string
  brokerageTaxID: string
  description: string
  errors: FormErrorType
  feeObj: CommissionItemValueType
  name: string
  office: ID
  paymentMethod: PaymentMethodEnum
  phone: string
  routingNumber: string
  type: ReferralTypeEnum
  user: ID
}

export interface FormErrorType {
  accountNumber?: string
  amount?: string
  bankName?: string
  brokerageName?: string
  brokerageTaxID?: string
  city?: string
  currency?: string
  description?: string
  office?: string
  paymentMethod?: string
  phone?: string
  routingNumber?: string
  state?: string
  street?: string
  type?: string
  user?: string
  zipCode?: string
}

export interface OptionType {
  key: ID
  text: string
  value: ID
}

export interface SearchOptionType {
  _id: ID
  description: string | null
  title: string
}

export interface EscrowRecipientType {
  _id: ID
  accountNumber: string
  addresses: AddressType[]
  bankName: string
  routingNumber: string
  taxID: string
}

export enum ModeEnum {
  Add,
  Edit
}

export enum PaymentMethodEnum {
  Ach = 'ACH',
  Check = 'Check',
}

export enum ReferralTypeEnum {
  Escrow = 'PayViaEscrow',
  External = 'External',
  Internal = 'Internal',
  ThirdParty = 'ThirdParty',
}
