import {
  set,
  unset
} from 'lodash'
import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import Button from 'shared/Button'
import TransactionSearchModal from './TransactionSearchModal'

import { getTransactionBoards, getTransactions } from '../TransactionQueries'
import { formatKanbanData, formatListData } from '../Utils/FormattingData'

// Import Store Types, Actions and Reducers
import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Transactions/Actions'

import { OptionType } from './Types'
import { ListViewTransactionType } from 'app/Transactions/Dashboard/Types'
import { Filter, FiltersData, FilterText, Transaction } from 'store/Transactions/Types'

// Import Styled Components
import {
  Plus,
  SearchContainer,
  SearchInput,
  SearchWrapper,
  Submit,
  Tag,
  TagContainer,
} from './Styled'

// Font Awesome Icons
import { faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faSearch, faTimes])

interface StoreProps {
  isKanbanView: boolean
  listData: ListViewTransactionType[]
  setAllRecentTransactions: (allTransactions: Transaction[], allListTransactions: ListViewTransactionType[]) => void
  setFilter: (data: Filter) => void
  setSearchDataFilter: (data: FiltersData) => void
  setSearchLoader: (open: boolean) => void
  transactionOwnerID: string
  transactions: Transaction[]
}

type Props = StoreProps & RouteComponentProps<{}, {}>

interface State {
  data: FiltersData
  filter: FiltersData
  filterText: FilterText[]
  open: boolean
  searchText: string
  selectedCity: OptionType
  selectedState: OptionType
}

class TransactionSearchBar extends React.Component<Props, State> {
  public state = {
    data: {
      address: {
        city: undefined,
        state: undefined,
        zipCode: undefined
      },
      mlsId: undefined,
      owner: {
        firstName: undefined,
        lastName: undefined
      },
      transactionId: undefined,
      transactionRole: undefined
    },
    filter: {},
    filterText: [],
    open: false,
    searchText: '',
    selectedCity: {
      text: '',
      value: ''
    },
    selectedState: {
      text: '',
      value: ''
    }
  }

  public render() {
    const { filterText, open, searchText, data, selectedState, selectedCity } = this.state
    return (
      <SearchWrapper>
        <SearchContainer>
          <div style={{ display: filterText.length === 0 ? 'none' : 'flex' }}>
            <TagContainer>
              {filterText.map((items: any, index: number) => (
                <Tag key={index}>
                  {items.content}
                  <span className="times" onClick={() => this.deleteFilterText(items.id, items.text)}>
                    <FontAwesomeIcon icon={['far', 'times']} />
                  </span>
                </Tag>
              ))}
            </TagContainer>
            <Plus>+</Plus>
          </div>
          <SearchInput
            action={(
              <Submit
                type='submit'
                onClick={this.handleSearch}
              >
                <FontAwesomeIcon icon={faSearch} />
              </Submit>
            )}
            icon={(searchText.length || filterText.length) && (
              <Button.Bubble
                icon={faTimes}
                size={16}
                type='danger'
                onClick={this.resetFilters}
              />
            )}
            fluid={true}
            placeholder="Search"
            onClick={this.handleOpen}
            onChange={this.handleTextInputChange}
            value={searchText}
          />
        </SearchContainer>
        {open && (
          <TransactionSearchModal
            data={data}
            filterText={filterText}
            selectedCity={selectedCity}
            selectedState={selectedState}
            filter={this.editFilter}
            filtersContent={this.selectFilter}
            closeAdvanceSearchModal={this.handleOpen}
          />
        )}
      </SearchWrapper>
    )
  }

  private editFilter = (data: FiltersData, state: OptionType, city: OptionType) => {
    const newList = this.state.filterText.slice()
    this.setState({ data, selectedState: state, selectedCity: city })
    const searchObj = {}
    newList.forEach((element: any) => {
      if (this.state.searchText !== undefined && this.state.searchText !== '') {
        searchObj['searchText'] = this.state.searchText
      }
      if (element.text in data) {
        searchObj[element.text] = data[element.text]
      }
    })
    this.setState({ filter: searchObj })
  }

  private handleSearch = async () => {
    const {
      setSearchLoader,
      setSearchDataFilter,
      transactionOwnerID,
      location,
      listData,
      isKanbanView,
    } = this.props
    const { filter } = this.state
    setSearchDataFilter(filter)
    setSearchLoader(true)
    const { setAllRecentTransactions } = this.props

    let kanbanParams:any = { searchData: filter }
    let listParams:any = { searchData: filter }

    if (transactionOwnerID && location && location.search) {
      kanbanParams.user = transactionOwnerID
      listParams.shareWith = transactionOwnerID
    }

    const kanban = await getTransactionBoards(kanbanParams)
    let list = [ ...listData ]
    if (!isKanbanView) {
      list = await getTransactions(listParams)
    }

    const transactionKanbanData = await formatKanbanData(kanban)
    const transactionListData = await formatListData(list)
    setAllRecentTransactions(transactionKanbanData, transactionListData)
    setSearchLoader(false)
  }

  private deleteFilterText = (id: string, text: string) => {
    const { setFilter } = this.props
    const { filterText, searchText } = this.state
    const newList = filterText.slice()
    if (this.state.searchText === '') {
      unset(this.state.filter, 'searchText')
    }
    unset(this.state.filter, text)
    const listIndex = newList.findIndex((item: any) => {
      return item.id === id
    })
    newList.splice(listIndex, 1)
    const newData = {
      filter: newList.length !== 0 ? true : false,
      searchText: searchText ? true : false
    }
    setFilter(newData)
    this.setState({ filterText: newList })
    this.handleSearch()
  }

  private addFilter = (data: FiltersData) => {
    const newList = this.state.filterText.slice()
    this.setState({ data })
    const searchObj = {}
    newList.forEach((element: any) => {
      if (this.state.searchText !== undefined && this.state.searchText !== '') {
        searchObj['searchText'] = this.state.searchText
      }
      if (element.text in data && data[element.text] !== undefined) {
        searchObj[element.text] = data[element.text]
      }
    })
    this.setState({ filter: searchObj })
  }

  private selectFilter = (id: string, content: string, text: string, searchData: object) => {
    const { filterText, searchText } = this.state
    const { setFilter } = this.props
    if (filterText.length !== 0) {
      const strIndex = filterText.findIndex((item: any) => {
        return item.id === id
      })
      if (strIndex !== -1) {
        return
      }
    }
    const newContent = [...filterText, { id: id, content: content, text: text }]
    this.setState({ filterText: newContent }, () => this.addFilter(searchData))
    const newData = {
      filter: true,
      searchText: searchText ? true : false
    }
    setFilter(newData)
  }

  private resetFilters = () => {
    this.setState(
      {
        data: {
          address: {
            city: undefined,
            state: undefined,
            zipCode: undefined
          },
          mlsId: undefined,
          owner: {
            firstName: undefined,
            lastName: undefined
          },
          transactionId: undefined,
          transactionRole: undefined
        },
        filter: {},
        filterText: [],
        searchText: '',
        selectedCity: {
          text: '',
          value: ''
        },
        selectedState: {
          text: '',
          value: ''
        }
      },
      () => this.handleSearch()
    )
    unset(this.state.filter, 'searchText')
  }

  private handleTextInputChange = (_:any, { value }: any) => {
    const { filterText } = this.state
    const { setFilter } = this.props
    this.setState({ searchText: value, open: false }, () => {
      const status = {
        filter: !!filterText.length,
        searchText: !!value
      }
      setFilter(status)
    })
    set(this.state.filter, 'searchText', value)
    if (this.state.filter['searchText'] === '') {
      unset(this.state.filter, 'searchText')
    }
  }

  private handleOpen = () => {
    const { open } = this.state
    this.setState({ open: !open })
  }
}

const mapStateToProps = (state: AppState) => ({
  isKanbanView: state.transactions.showDefaultView,
  listData: state.transactions.listData,
  transactionOwnerID: state.transactions.transactionOwnerID,
  transactions: state.transactions.allTransactions
})

export default withRouter(
  connect(
    mapStateToProps,
    {
      setAllRecentTransactions: Actions.getAllRecentTransactions,
      setFilter: Actions.filter,
      setSearchDataFilter: Actions.searchDataFilter,
      setSearchLoader: Actions.searchLoader
    }
  )(TransactionSearchBar)
)
