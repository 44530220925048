import React, {FC, memo, SyntheticEvent, useEffect, useState} from 'react'
import { Icon, Input } from 'semantic-ui-react'

import fixedOrPercent from 'shared/FixedOrPercent'

import {
  CurrencyDropdown,
  SaveButton,
} from './Styled'

import {
  PaymentNumericEnum,
} from 'store/Transactions/Types'

// dropdown options
const options = [
  { key: '$', text: '$', value: PaymentNumericEnum.Fixed },
  { key: '%', text: '%', value: PaymentNumericEnum.Percent }
]

interface Props {
  action(name: string, value: number): Promise<void>
  commissionName: string
  editable: boolean
  initialAmount: number
  placeholder?: string
  total: number
}

const FinancialSummaryCommissionAmount: FC<Props> = ({
  action,
  commissionName,
  editable,
  initialAmount,
  placeholder,
  total,
}) => {

  // Local state
  const [amount, setAmount]     = useState(initialAmount)
  const [currency, setCurrency] = useState(PaymentNumericEnum.Fixed)
  const [dirty, setDirty]       = useState(false) // form values have been modified
  const [loading, setLoading]   = useState(false)
  
  // handlers
  const changeHandlers = {
    amount: (commission: string) => { setAmount(parseFloat(commission)) },
    currency: (currency: PaymentNumericEnum) => { setCurrency(currency) },
  }

  useEffect(() => {
    setAmount(initialAmount)
  }, [initialAmount])

  // User can't edit. Return non editable text
  if (!editable) {
    return (
      <span style={{ marginRight: 10 }}>
              ${initialAmount}
      </span>
    )
  }

  const submit = async () => {
    if (dirty) {
      setLoading(true)
      await action(commissionName, currency === PaymentNumericEnum.Percent ? amount * total / 100 : amount)
      setCurrency(PaymentNumericEnum.Fixed)
      setDirty(false)
      setLoading(false)
    }
  }

  const changeValue = (e: SyntheticEvent<HTMLInputElement>, { name, value }: any): void => {
    e.preventDefault()
    e.stopPropagation()
    setDirty(true)
    changeHandlers[name](value)
  }

  return (
    <>
      <Input
        className="amount"
        disabled={loading}
        size="mini"
        type="number"
        placeholder={placeholder}
        name="amount"
        value={amount}
        onChange={changeValue}
        />
    <CurrencyDropdown
        compact={true}
        disabled={loading}
        name='currency'
        onChange={changeValue}
        options={options}
        placeholder={'$'}
        search={true}
        selection={true}
        value={fixedOrPercent(currency, PaymentNumericEnum.Fixed, PaymentNumericEnum.Percent)}
      />
      <SaveButton
        className={dirty ? 'dirty' : undefined}
        disabled={loading}
        icon={!loading}
        loading={loading}
        labelPosition='left'
        onClick={submit}
      > 
      <Icon name='save outline' /> SAVE</SaveButton>
    </>
  )
}

export default memo(FinancialSummaryCommissionAmount)