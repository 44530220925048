import { ActionCreator } from 'redux'
import Actions from 'store/Actions'
import {
  CapChangeEvent,
  CommissionAmount,
  CommissionFeeChangeEvent,
  CommissionTypeChangeEvent,
  ExpectedCommissionChangeEvent,
  OtherFeeAddEvent,
  OtherFeeChangeEvent,
  OtherFeeDeleteEvent,
  OtherFeeNewData,
  PrincipalChangeEvent,
  ProgressiveFeeAddEvent,
  ProgressiveFeeChangeEvent,
  ProgressiveFeeDeleteEvent,
  ProgressiveFeeNewData,
  PropertyFeeChangeEvent,
  PropertyFeeNewData,
  ToggleCapsEvent
} from './Types'

export const changeOtherFeeItem: ActionCreator<OtherFeeChangeEvent> = (
  id: string,
  newData: OtherFeeNewData
) => ({
  payload: {
    id,
    newData
  },
  type: Actions.changeOtherFeeItem
})

export const addOtherFeeItem: ActionCreator<OtherFeeAddEvent> = () => ({
  type: Actions.addOtherFeeItem
})

export const deleteOtherFeeItem: ActionCreator<OtherFeeDeleteEvent> = (
  id: string
) => ({
  payload: {
    id
  },
  type: Actions.deleteOtherFeeItem
})

export const changeCommissionFee: ActionCreator<CommissionFeeChangeEvent> = (
  newAmount: CommissionAmount
) => ({
  payload: {
    newAmount
  },
  type: Actions.changeCommissionFee
})

export const changeCommissionType: ActionCreator<CommissionTypeChangeEvent> = (
  type: string,
  newAmount: CommissionAmount
) => ({
  payload: {
    newAmount,
    type
  },
  type: Actions.changeCommissionType
})

export const changeResidentialFeeProperty: ActionCreator<
  PropertyFeeChangeEvent
> = (newData: PropertyFeeNewData) => ({
  payload: {
    newData
  },
  type: Actions.changeResidentialFeeProperty
})

export const changeCommercialFeeProperty: ActionCreator<
  PropertyFeeChangeEvent
> = (newData: PropertyFeeNewData) => ({
  payload: {
    newData
  },
  type: Actions.changeCommercialFeeProperty
})

export const changeProgressiveCommissionItem: ActionCreator<
  ProgressiveFeeChangeEvent
> = (id: string, newData: ProgressiveFeeNewData) => ({
  payload: {
    id,
    newData
  },
  type: Actions.changeProgressiveCommissionItem
})

export const addProgressiveCommissionItem: ActionCreator<
  ProgressiveFeeAddEvent
> = () => ({
  type: Actions.addProgressiveCommissionItem
})

export const deleteProgressiveCommissionItem: ActionCreator<
  ProgressiveFeeDeleteEvent
> = (id: string) => ({
  payload: {
    id
  },
  type: Actions.deleteProgressiveCommissionItem
})

export const changeExpectedCommission: ActionCreator<
  ExpectedCommissionChangeEvent
> = (newValue: number) => ({
  payload: {
    newValue
  },
  type: Actions.changeExpectedCommission
})

export const changePrincipalPropertyCost: ActionCreator<
  PrincipalChangeEvent
> = (newValue: number) => ({
  payload: {
    newValue
  },
  type: Actions.changePrincipalPropertyCost
})

export const changeResidentialCap: ActionCreator<CapChangeEvent> = (
  newValue: number
) => ({
  payload: {
    newValue
  },
  type: Actions.changeResidentialCap
})

export const changeCommercialCap: ActionCreator<CapChangeEvent> = (
  newValue: number
) => ({
  payload: {
    newValue
  },
  type: Actions.changeCommercialCap
})

export const changeTeamCap: ActionCreator<CapChangeEvent> = (
  newValue: number
) => ({
  payload: {
    newValue
  },
  type: Actions.changeTeamCap
})

export const changeAdditionalCap: ActionCreator<CapChangeEvent> = (
  newValue: number
) => ({
  payload: {
    newValue
  },
  type: Actions.changeAdditionalCap
})

export const changeExclusionCap: ActionCreator<CapChangeEvent> = (
  newValue: number
) => ({
  payload: {
    newValue
  },
  type: Actions.changeExclusionCap
})

export const toggleCaps: ActionCreator<ToggleCapsEvent> = () => ({
  payload: {},
  type: Actions.toggleCaps
})
